import React from "react";
import { Route, Redirect } from "react-router-dom";
import { checkAuthentication } from "../services/auth";
import { connect } from "react-redux";

const LoggedInRoute = (props) => {
  const isAuthenticated = checkAuthentication(props.authToken);
  return isAuthenticated ? (
    <Route
      path={props.path}
      exact={props.exact}
      render={(routeProps) => (
        <React.Fragment>
          <props.component></props.component>
        </React.Fragment>
      )}
    />
  ) : (
    <Redirect to={"/"} />
  );
};

const mapStateToProps = (state) => {
  return {
    authToken: state.persist.authToken,
  };
};

export default connect(mapStateToProps)(LoggedInRoute);
