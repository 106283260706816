import * as React from "react";
import { Route, Redirect } from "react-router-dom";
import { checkAuthentication } from "../services/auth";
import { connect } from "react-redux";
const LoggedOutRoute = (props) => {
  const isAuthenticated = checkAuthentication(props.authToken);

  console.log("deepdebug", isAuthenticated);

  return !isAuthenticated ? (
    <Redirect to={"/"} />
  ) : (
    <Route path={props.path} exact={props.exact} component={props.component} />
  );
};
const mapStateToProps = (state) => {
  return {
    authToken: state.persist.authToken,
  };
};

export default connect(mapStateToProps)(LoggedOutRoute);
