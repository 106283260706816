import React from "react";
import NavbarSuperAdmin from "../navbar/NavbarSuperAdmin";
import styles from "./SuperAdminLayout.module.css";

const SuperAdminLayout = ({ children, hideNavbar }) => {
  return (
    <>
      {!hideNavbar && <NavbarSuperAdmin />}
      <div className={styles.container}>
        <div className={styles.innerContainer}>{children}</div>
      </div>
    </>
  );
};

export default SuperAdminLayout;
