import React, { useEffect, useState } from "react";
import styles from "./MemberDetails.module.css";
import NavbarSuperAdmin from "../../../components/navbar/NavbarSuperAdmin";
import SuperAdminLayout from "../../../components/partials/SuperAdminLayout";
import { GoToBack } from "../../../components/ui/GoToBack header/GoToBack";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";

const data = [
  {
    text: "Notes",
    link: "/notes",
  },
  {
    text: "Daily Tasks",
    link: "/dailytask",
  },
  {
    text: "Medicine Management",
    link: "/medicinemanagement",
  },
  {
    text: "Appointments",
    link: "/appointments",
  },
  {
    text: "Calendar",
    link: "/calendar",
  },
  {
    text: "Call Log",
    link: "/call-log",
  },
  {
    text: "Social Messenger",
    link: "/chat",
  },
  {
    text: "Social Network",
    link: "/media-gallery",
  },
  {
    text: "Add Care Giver",
    link: "/addcaregiver",
  },
  { text: "Care Givers List", link: "/caregiverslist" },
];

const MemberDetails = (props) => {
  const [time, setTime] = useState({
    time: new Date()
      .toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      })
      .split(" ")[0],
    period: new Date().toLocaleTimeString([], { hour12: true }).split(" ")[1],
  });
  const [todayDate, setTodayDate] = useState(new Date().toLocaleDateString());

  useEffect(() => {
    const timer = setInterval(() => {
      const currentTime = new Date();
      const curTime = currentTime
        .toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        })
        .split(" ")[0];

      setTime({
        time: curTime,
        period: currentTime
          .toLocaleTimeString([], { hour12: true })
          .split(" ")[1],
      });

      setTodayDate(new Date().toLocaleDateString());
    }, 30000);

    return () => clearInterval(timer); // Cleanup the interval on component unmount
  }, []);

  let patientData;

  if (props.userData && props.userData?.network?.members?.length) {
    patientData = props.userData.network.members.find(
      (el) => el._id === props.userData._id
    );
    patientData = {
      ...patientData,
      networkName: props.userData?.network?.networkName,
    };

    if (props.userData.hospital) {
      patientData = {
        ...patientData,
        hospital: props.userData.hospital.hospitalName,
      };
    }
    if (props.userData.relationship) {
      patientData = {
        ...patientData,
        relationship: props.userData.relationship.name,
      };
    }
  }

  return (
    <>
      <SuperAdminLayout hideNavbar>
        {/* <GoToBack>{memberData?.firstName}'s Details</GoToBack> */}

        <div className={styles.container}>
          <div className={styles.headingContainer}>
            <h2 className={styles.heading}>Dashboard</h2>

            <div className={styles.dateTimeContainer}>
              <h4 className={styles.time}>{`${todayDate} - `}</h4>
              <h4 className={styles.time}>
                {`${time.time} `}
                <span>{time.period}</span>
              </h4>
            </div>
          </div>
          <div className={styles.cardContainer}>
            {data.map((item, i) => (
              <Link key={i} to={`/${patientData._id}${item.link}`}>
                <div className={styles.card}>
                  <p>{item.text}</p>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </SuperAdminLayout>
    </>
  );
};

// export default MemberDetails;

const mapStateToProps = (state) => {
  return {
    memberList: state.memberListReducer.memberList,
    userData: state.persist.userData,
  };
};

export default connect(mapStateToProps)(MemberDetails);
