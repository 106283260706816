import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  FormGroup,
  FormControl,
  ControlLabel,
  InputGroup,
  Row,
  Col,
} from "react-bootstrap";
import { ArrowCounterclockwise, Cursor, EyeFill } from "react-bootstrap-icons";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { toast } from "react-toastify";
import { IoIosSkipForward } from "react-icons/io";
import { API_BASE_PATH } from "../../constants/workerConstants";
import axios from "axios";
import { connect } from "react-redux";

function AddPatient(props) {
  const history = useHistory();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [landLine, setLandLine] = useState("");
  const [emailId, setEmailId] = useState("");
  const [connexName, setConnexName] = useState("");
  const [address, setAddress] = useState("");
  const [apartment, setApartment] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState("password");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState("password");
  const [quest, setQuest] = useState("");
  const [answer, setAnswer] = useState("");
  const [age, setAge] = useState("");
  const [memberName, setMemberName] = useState("");
  const [specialNeeds, setSpecialNeed] = useState("");
  const [username, setUserName] = useState("");

  // console.log("props:", props);
  const {
    patientData = "",
    isReadOnly,
    isTitle = true,
    networkID,
    isAddSenior,
    editProfile,
  } = props;

  console.log("profile firstName", firstName);

  //
  useEffect(() => {
    if (!patientData || !editProfile) return;

    setFirstName(patientData.firstName);
    setLastName(patientData.lastName);
    setLandLine(patientData.landline);
    setEmailId(patientData.emailId);
    setConnexName(patientData.nickName);
    setAddress(patientData.address);
    setApartment(patientData.apartmentNumber);
    setCity(patientData.city);
    setState(patientData.state);
    setZipCode(patientData.zipCode);
    setPhone(patientData.phno);
    setPassword(patientData.password);
    setConfirmPassword(patientData.password);
    setQuest(patientData.securityQuestion);
    setAnswer(patientData.answer);
    setAge(patientData.age);
    setMemberName(patientData.othersLivingInHome);
    setSpecialNeed(patientData.specialNeeds);
    setUserName(patientData.username);
  }, [patientData, editProfile]);

  const style = {
    marginBottom: "25px",
    background: "#FFFFFF",
    border: "none",
    boxShadow: "0px 2px 1px rgb(0 0 0 /25%)",
    borderRadius: " 11px",
  };
  const submitForm = (e) => {
    e.preventDefault();
    if (firstName.trim() == "") return toast.error("Enter First Name");
    if (lastName.trim() == "") return toast.error("Enter Last Name");
    if (emailId.trim() == "") return toast.error("Enter Email Id ");
    if (username.trim() == "") return toast.error("Enter User Name");
    if (
      !String(emailId)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    )
      return toast.error("Enter Correct Email Id ");
    if (age.trim() == "") return toast.error("Enter Age");
    if (connexName.trim() == "") return toast.error("Enter Nick name");
    if (address.trim() == "") return toast.error("Enter Address");
    if (city.trim() == "") return toast.error("Enter City");
    if (state.trim() == "") return toast.error("Enter State");
    if (zipCode.trim() == "") return toast.error("Enter Zip Code");
    if (quest.trim() == "") return toast.error("Select Security Question");
    if (answer.trim() == "") return toast.error("Enter Security Answer");
    if (password.trim() == "") return toast.error("Enter Password");
    if (password.length <= 5)
      return toast.error("Password Should be atleast 6 character");
    if (password !== confirmPassword)
      return toast.error("Confirm Password does not match");

    const data = {
      firstName,
      lastName,
      landline: landLine,
      emailId,
      username,
      othersLivingInHome: memberName,
      age,
      nickName: connexName,
      specialNeeds,
      phno: phone,
      address,
      apartmentNumber: apartment,
      city,
      state,
      zipCode,
      securityQuestion: quest,
      answer,
      userRole: 1,
      deviceType: "WEB",
      network: networkID ? networkID : window.localStorage.getItem("netowrkID"),
    };

    if (!editProfile) {
      data["password"] = password;
    }

    axios
      .post(
        `${API_BASE_PATH}${
          editProfile ? "/user/updateUserProfile" : "/user/register"
        }`,
        data,
        {
          headers: {
            "content-type": "application/json",
            authorization: props.authToken,
          },
        }
      )
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success(res.data.responseMessage);
          if (!editProfile) {
            getMemberList();
          }
        }
      })
      .catch((err) => {
        toast.error(err.response.data.responseMessage);
      });
  };

  function getMemberList() {
    axios
      .get(`${API_BASE_PATH}/user/getMemberList`, {
        headers: {
          authorization: props.authToken,
        },
      })
      .then((resp) => {
        if (resp.status === 200) {
          console.log("memersList:", resp.data.result);
          props.getMemberList(resp.data.result);
        }
      })
      .catch((err) => {})
      .finally(() => {
        if (isAddSenior) return window.history.back();
        else return history.push("/");
      });
  }

  const inputFields = [
    {
      label: "First Name",
      isRequired: true,
      type: "text",
      value: patientData?.firstName || firstName,
      handleOnChange: (e) => setFirstName(e.target.value),
      md: 6,
    },
    {
      label: "Last Name",
      isRequired: true,
      type: "text",
      value: patientData?.lastName || lastName,
      handleOnChange: (e) => setLastName(e.target.value),
      md: 6,
    },
    {
      label: "Email",
      isRequired: true,
      type: "text",
      value: patientData?.emailId || emailId,
      handleOnChange: (e) => setEmailId(e.target.value),
      md: 6,
    },
    {
      label: "User Name",
      isRequired: true,
      type: "text",
      value: patientData?.username || username,
      handleOnChange: (e) => setUserName(e.target.value),
      md: 3,
    },
    {
      label: "Landline",
      isRequired: false,
      type: "text",
      value: patientData?.landline || landLine,
      handleOnChange: (e) => setLandLine(e.target.value),
      md: 3,
    },
    {
      label: "Name of others living in home",
      isRequired: false,
      type: "text",
      value: patientData?.othersLivingInHome || memberName,
      handleOnChange: (e) => setMemberName(e.target.value),
      md: 6,
    },
    {
      label: "Special Needs",
      isRequired: false,
      type: "text",
      value: patientData?.specialNeeds || specialNeeds,
      handleOnChange: (e) => setSpecialNeed(e.target.value),
      md: 6,
    },
    {
      label: "Age",
      isRequired: true,
      type: "text",
      value: patientData?.age || age,
      handleOnChange: (e) => setAge(e.target.value),
      md: 6,
    },
    {
      label: "Enter Nick Name",
      isRequired: true,
      type: "text",
      value: patientData?.nickName || connexName,
      handleOnChange: (e) => setConnexName(e.target.value),
      md: 6,
    },
    {
      label: "Phone Number",
      name: "phone",
      isRequired: false,
      type: "number",
      value: patientData?.phno || phone,
      handleOnChange: setPhone,
      md: 6,
    },
    {
      label: "Address",
      isRequired: true,
      type: "text",
      value: patientData?.address || address,
      handleOnChange: (e) => setAddress(e.target.value),
      md: 6,
    },
    {
      label: "Apartment No.",
      isRequired: false,
      type: "text",
      value: patientData?.apartmentNumber || apartment,
      handleOnChange: (e) => setApartment(e.target.value),
      md: 3,
    },
    {
      label: "City",
      isRequired: true,
      type: "text",
      value: patientData?.city || city,
      handleOnChange: (e) => setCity(e.target.value),
      md: 3,
    },
    {
      label: "State",
      isRequired: true,
      type: "text",
      value: patientData?.state || state,
      handleOnChange: (e) => setState(e.target.value),
      md: 3,
    },
    {
      label: "Zip Code",
      isRequired: true,
      type: "text",
      value: patientData?.zipCode || zipCode,
      handleOnChange: (e) => setZipCode(e.target.value),
      md: 3,
    },
    {
      label: "Security Question",
      isRequired: true,
      type: "select",
      value: patientData?.securityQuestion || quest,
      handleOnChange: (e) => setQuest(e.target.value),
      md: 6,
      option: [
        "what city were you born in?",
        "what is your favourite food?",
        "what street did you grow up on?",
        "what is your mother’s maiden name?",
        "where did you meet your spouse?",
        "what Is your favorite book?",
      ],
    },
    {
      label: "Security Answer",
      isRequired: true,
      type: "text",
      value: patientData?.answer || answer,
      handleOnChange: (e) => setAnswer(e.target.value),
      md: 6,
    },
    {
      label: "Password",
      name: "password",
      isRequired: true,
      type: showPassword,
      value: patientData?.password || password,
      handleOnChange: (e) => setPassword(e.target.value),
      handleShowPassword: () =>
        setShowPassword((prev) => (prev === "password" ? "text" : "password")),
      md: 6,
    },
    {
      name: "confirm password",
      label: "Confirm Password",
      isRequired: true,
      type: showConfirmPassword,
      value: patientData?.password || confirmPassword,
      handleOnChange: (e) => setConfirmPassword(e.target.value),
      handleShowPassword: () =>
        setShowConfirmPassword((prev) =>
          prev === "password" ? "text" : "password"
        ),
      md: 6,
    },
  ];

  if (isReadOnly) {
    inputFields.pop();
    inputFields.pop();
  }

  return (
    <div style={{ padding: "0px 280px", minWidth: "400px" }}>
      <form style={{ marginTop: "10px" }} onSubmit={submitForm}>
        {isTitle && (
          <>
            <p
              style={{ cursor: "pointer", float: "right" }}
              onClick={() => history.push("/")}
            >
              Skip <IoIosSkipForward />
            </p>
            <h5
              style={{ fontWeight: 700, color: "#303030", textAlign: "left" }}
            >
              Add Patient
            </h5>

            <br />
            <br />
          </>
        )}
        <Row
          style={{
            background: "#ECECEC",
            padding: "1rem",
            marginTop: "-2rem",
            borderRadius: " 5px",
          }}
        >
          {inputFields.map((item) => (
            <Col key={item.label} md={item.md}>
              <FormGroup bsSize="large" style={{ position: "relative" }}>
                {editProfile && item.label.includes("Password") ? (
                  ""
                ) : (
                  <ControlLabel style={{ marginBottom: "10px" }}>
                    <span style={{ color: "#303030" }}>{item.label}</span>
                    {item.isRequired ? (
                      <b style={{ color: "red" }}>*</b>
                    ) : (
                      "(Optional)"
                    )}
                  </ControlLabel>
                )}

                {item.type == "text" &&
                  item.name !== "password" &&
                  item.name !== "confirm password" && (
                    <FormControl
                      type={item.type}
                      value={item.value}
                      onChange={item.handleOnChange}
                      style={style}
                      disabled={isReadOnly}
                    />
                  )}

                {item.name === "phone" && (
                  <>
                    {isReadOnly && (
                      <div
                        style={{
                          position: "absolute",
                          top: "0",
                          bottom: "0",
                          zIndex: "2",
                          right: "0",
                          left: "0",
                        }}
                      ></div>
                    )}
                    <PhoneInput
                      value={item.value}
                      onChange={item.handleOnChange}
                      defaultCountry="US"
                    />
                  </>
                )}

                {item.type === "select" && (
                  <FormControl
                    componentClass={"select"}
                    value={item.value}
                    onChange={item.handleOnChange}
                    style={style}
                    disabled={isReadOnly}
                  >
                    <option key="0"></option>
                    {item.option.map((text) => (
                      <option key={text} value={text}>
                        {text}
                      </option>
                    ))}
                  </FormControl>
                )}

                {!editProfile &&
                (item.name === "password" ||
                  item.name === "confirm password") ? (
                  <InputGroup style={{ position: "relative" }}>
                    <FormControl
                      type={item.type}
                      value={item.value}
                      onChange={item.handleOnChange}
                      style={style}
                      disabled={isReadOnly}
                    />
                    <InputGroup.Addon
                      style={{
                        borderLeft: "0",
                        verticalAlign: "middle",
                        textAlign: "center",
                        position: "absolute",
                        zIndex: "9999",
                        bottom: "0.5rem",
                        right: "0.6rem",
                        top: "7px",
                      }}
                      onClick={item.handleShowPassword}
                    >
                      <EyeFill />
                    </InputGroup.Addon>
                  </InputGroup>
                ) : (
                  ""
                )}
              </FormGroup>
            </Col>
          ))}

          {!isReadOnly && (
            <div className="text-center mt-3">
              <Button
                onClick={submitForm}
                className="btn "
                bsSize="medium"
                style={{
                  backgroundColor: "rgb(29, 117, 189)",
                  color: "rgb(255, 255,255)",
                  borderRadius: "12px",
                  padding: "0.3rem 3rem 0.3rem 3rem",
                }}
              >
                {editProfile ? "Update" : "Submit"}
              </Button>
            </div>
          )}
        </Row>
      </form>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    authToken: state.persist.authToken,
    memberList: state.memberListReducer.memberList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMemberList: (data) =>
      dispatch({
        type: "memberList/data",
        payload: data,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPatient);
