import React from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import NavbarSuperAdmin from "../../../components/navbar/NavbarSuperAdmin";
import { GoToBack } from "../../../components/ui/GoToBack header/GoToBack";
import AddPatient from "../../addPatient/AddPatient";
import Loader from "../../../components/Loader/Loader";

const PatientProfile = (props) => {
  const { id } = useParams();

  let patientData;

  if (props.memberList && props.memberList?.network?.members?.length)
    patientData = props.memberList.network.members.find((el) => el._id === id);

  console.log("patientData", patientData);

  return (
    <div>
      {/* <Loader /> */}
      <NavbarSuperAdmin />

      <div className="pl">
        {patientData && <GoToBack>{patientData.firstName}'s Profile</GoToBack>}
        {patientData && (
          <AddPatient
            isReadOnly={true}
            patientData={patientData}
            isTitle={false}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authToken: state.persist.authToken,
    memberList: state.memberListReducer.memberList,
  };
};

export default connect(mapStateToProps)(PatientProfile);
