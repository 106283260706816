import React, { useState } from "react";
import NavbarSuperAdmin from "../../../components/navbar/NavbarSuperAdmin";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import TableBody from "@material-ui/core/TableBody";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import axios from "axios";
import { connect } from "react-redux";
import { API_BASE_PATH } from "../../../constants/workerConstants";
import { Paper } from "@material-ui/core";
import ContentDialog from "../../../components/ui/app-modal/ContentDialog";
import AddDialog from "../../../components/ui/app-modal/AddDialog";
import dateFormat from "dateformat";
import Spinner from "../Spinner";
import { FaPlusCircle, FaMinusCircle } from "react-icons/fa";

function Calender(props) {
  const [dataDays, setDataDays] = useState([]);
  const [changeDate, setChangeDate] = useState(new Date().toDateString());
  const [findTask, setFindTask] = useState();
  const [loading, setLoading] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    date: "",
  });
  const [addDialog, setAddDialog] = useState({
    isOpen: false,
    date: "",
    xyzFun: "",
    text: "",
    apiText: "",
  });

  const values = dataDays.map((item) => {
    var d = new Date(item.date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    var date = [year, month, day].join("-");
    var title = item.title;
    return { title, date, color: item.color };
  });

  function handleDateClick(data) {
    getDateData(data.date + "");
  }

  function getDates(dateInfo) {
    var chengenewDate = new Date().toUTCString();

    var checkDate = new Date(
      dateInfo.view.getCurrentData().currentDate
    ).toUTCString();

    if (chengenewDate.substring(0, 16) !== checkDate.substring(0, 16))
      setChangeDate(new Date(dateInfo.view.getCurrentData().currentDate));
  }

  async function getDateData(reqDate) {
    setLoading(true);
    axios
      .post(
        `${API_BASE_PATH}/todo/CalendarsDataForMonth`,
        {
          reqDate,
        },
        {
          headers: {
            authorization: props.authToken,
          },
        }
      )
      .then((resp) => {
        if (resp.status !== 200) throw new Error("Unexpected response status");

        const response = resp.data.result;

        console.log("Response:", response);

        const days = Object.entries(response)
          .map(([type, { days }]) =>
            days.map((el) => {
              let color;
              if (type === "medication") color = "#f9e6d6";
              if (type === "appointment") color = "#b2f2bb";
              if (type === "note") color = "#ffffcd";
              if (type === "todo") color = "#ffd8a8";

              return { ...el, color };
            })
          )
          .flat(Infinity);

        // console.log("days:", days);
        setDataDays(days);

        const data = Object.entries(response)
          .map(([type, { data }]) => ({ type, data }))
          .filter((el) => el.data.length);

        data
          .find((el) => el.type === "medication")
          ?.data.map((el) =>
            el.days.splits.map((item) => {
              el.content = item.content;
              el.time = item.time;
              el.date = el.days.date;
            })
          );

        setFindTask(data);
        console.log("data:", data);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }

  React.useEffect(() => {
    const reqDate = "" + new Date(changeDate);
    getDateData(reqDate);
  }, [changeDate]);

  return (
    <div>
      <NavbarSuperAdmin />

      <div style={{ position: "relative" }}>
        <Table
          style={{
            marginTop: "15px",
            marginBottom: "100px",
            marginLeft: "82px",
            width: "77%",
          }}
        >
          <TableHead>
            <div class="header" style={{ marginTop: "0px" }}>
              <KeyboardBackspaceIcon
                onClick={() => window.history.back()}
                style={{
                  marginTop: "70px",
                  marginLeft: "-7px",
                  height: "40px",
                  width: "40px",
                }}
              />
              <h4
                style={{
                  position: "relative",
                  marginTop: "-34px",
                  marginLeft: "40px",
                  width: "100%",
                }}
              >
                <b>Calendar</b>
              </h4>
              <div
                style={{
                  height: "60px",
                  position: "relative",
                  cursor: "pointer",
                }}
              >
                <FullCalendar
                  height="auto"
                  width="60%"
                  plugins={[dayGridPlugin, interactionPlugin]}
                  events={values}
                  dateClick={handleDateClick}
                  initialView="dayGridMonth"
                  eventBorderColor="#ffffcd"
                  eventBackgroundColor="#ffffcd"
                  datesSet={getDates}
                  eventTextColor="black"
                  selectable="true"
                />
              </div>
              {loading && <Spinner />}
              <div
                style={{
                  display: "flex",
                  marginLeft: "80%",
                  marginTop: "-62px",
                }}
              >
                <div
                  className="top"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "83%",
                  }}
                >
                  <button
                    type="button"
                    class="btn btn-primary"
                    style={{
                      marginLeft: "101%",
                      alignItems: "right",
                      width: "414%",
                      maxHeight: "10%",
                      paddingLeft: "2px",
                      backgroundColor: "rgb(29, 117, 189)",
                      color: "rgb(255, 255, 255)",
                    }}
                  >
                    Detail
                  </button>
                </div>
                <div className="down" style={{ marginTop: "57px" }}>
                  <Paper
                    variant="h4"
                    elevation={3}
                    style={{
                      minHeight: "460px",
                      textAlign: "center",
                      width: "240px",
                    }}
                  >
                    {findTask !== undefined && findTask.length === 0
                      ? "There is no data"
                      : " "}

                    {findTask !== undefined &&
                      findTask.length > 0 &&
                      findTask.map((value) => {
                        return (
                          <Items
                            value={value}
                            setConfirmDialog={setConfirmDialog}
                          />
                        );
                      })}
                  </Paper>
                </div>
              </div>
            </div>
          </TableHead>
          <TableBody></TableBody>
        </Table>
      </div>
      <ContentDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <AddDialog addDialog={addDialog} setAddDialog={setAddDialog} />
    </div>
  );
}
const mapStateToProps = (state) => {
  console.log(state);
  return {
    authToken: state.persist.authToken,
    userData: state.persist.userData,
  };
};
export default connect(mapStateToProps)(Calender);

function Items({ value, setConfirmDialog }) {
  const [expand, setExpand] = useState(true);
  return (
    <div>
      <div
        style={{
          color: "rgb(29, 117, 189)",
          paddingLeft: "3px",
          paddingTop: "10px",
        }}
      >
        <div
          style={{
            fontWeight: "bold",
            cursor: "pointer",
            textAlign: "left",
            padding: "0 5px 0 3px",
            textTransform: "capitalize",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          onClick={() => setExpand((e) => !e)}
        >
          <div>{value.type}</div>
          {expand && <FaMinusCircle color="#2C3E50" />}
          {!expand && value.type && <FaPlusCircle color="#2C3E50" />}
        </div>
        <div
          style={{
            textAlign: "left",
            maxHeight: "300px",
            overflowY: "auto",
          }}
        >
          {expand &&
            value.data.map((item) => (
              <ItemLists value={item} setConfirmDialog={setConfirmDialog} />
            ))}
        </div>
      </div>
      <hr />
    </div>
  );
}

function ItemLists({ value, setConfirmDialog }) {
  function handleDisplayData() {
    setConfirmDialog({
      isOpen: true,
      title: value.content,
      date: dateFormat(value.date, "mm/dd/yyyy"),
      reminder: value.reminder,
      time: value.time,
      location: value.location,
    });
  }

  return (
    <div
      style={{
        boxShadow:
          "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
        margin: "5px 0",
        padding: "5px",
        cursor: "pointer",
      }}
      onClick={handleDisplayData}
    >
      <div>
        {value.content?.length < 30 ? value.content : ""}
        {value.content?.length > 65 ? value.content.slice(0, 65) + "..." : ""}
      </div>
      <div> {dateFormat(value.date, "mm/dd/yyyy")}</div>
      <div> {value.time}</div>
      <div>{value.reminder}</div>
      <div>{value.location}</div>
    </div>
  );
}
