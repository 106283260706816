import React, { useState } from "react";
import { connect } from "react-redux";
import NavbarSuperAdmin from "../../../components/navbar/NavbarSuperAdmin";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import { useParams } from "react-router-dom";
import Axios from "axios";
import { API_BASE_PATH } from "../../../constants/workerConstants";
import { makeStyles } from "@material-ui/core/styles";
import ReactPaginate from "react-paginate";
import { Oxygen } from "../../../components/chart/Oxygen";

const SuperAdminBloodOxygen = (props) => {
  const classes = useStyles();

  const [data, setData] = useState([]);
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");
  const { id } = useParams();
  const [networkAdmin, setNetworkAdmin] = useState("");

  const [totalPages, setTotalPages] = React.useState([]);
  const [activePage, setActivePage] = React.useState(1);

  const getHeartRate = () => {
    let pages = [];

    Axios.post(
      `${API_BASE_PATH}/health/getBloodOxygen`,
      {
        _id: props.superAdminHeartID,
        page: "1",
        limit: "40",
      },
      {
        headers: {
          "content-type": "application/json",
        },
      }
    ).then((res) => {
      setRows(res.data.result.bloodOxygen);
      console.log(res.data.result.bloodOxygen);

      if (res.data.result.totalPage) {
        for (let i = 1; i <= res.data.result.totalPage; i++) {
          pages = [...pages, i];
        }

        // console.log("hehe", pages);
        setTotalPages(pages);
      }
    });
  };

  const nextPage = (data) => {
    setActivePage(data);

    Axios.post(
      `${API_BASE_PATH}/health/getBloodOxygen`,
      {
        _id: props.superAdminHeartID,
        page: data,
        limit: "40",
      },
      {
        headers: {
          "content-type": "application/json",
        },
      }
    ).then((res) => {
      setRows(res.data.result.bloodOxygen);
    });
  };

  React.useEffect(() => {
    console.log(props.superAdminHeartID);

    if (props.superAdminHeartID) {
      getHeartRate();
    }
  }, [props.superAdminHeartID]);

  function getColorClass(type, data) {
    data = parseInt(data);
    switch (type) {
      case "bloodOxygen":
        return data < 88
          ? classes.redColor
          : data > 88 && data < 95
          ? classes.yellow
          : "";
      default:
        return;
    }
  }

  return (
    <>
      <NavbarSuperAdmin />

      <div style={{ position: "relative" }}>
        <Table
          style={{
            marginTop: "70px",
            marginBottom: "100px",
            marginLeft: "183px",
            width: "77%",
          }}
        >
          <TableHead>
            <div class="header" style={{ marginTop: "0px" }}>
              <KeyboardBackspaceIcon
                onClick={() => window.history.back()}
                style={{
                  marginTop: "70px",
                  marginLeft: "-7px",
                  height: "40px",
                  width: "40px",
                }}
              />
              <h4
                style={{
                  position: "relative",
                  marginTop: "-34px",
                  marginLeft: "40px",
                  width: "100%",
                }}
              >
                <b>Blood Oxygen</b>
              </h4>
            </div>
 
          </TableHead>
          <TableBody>
            <Oxygen data={rows} />
          </TableBody>
        </Table>

        <div id={"react-paginate"} className="bottom-navi-custom">
          {totalPages !== null && totalPages.length ? (
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={totalPages.length}
              marginPagesDisplayed={2}
              pageClassName={"navi-li"}
              pageRangeDisplayed={2}
              onPageChange={(page) => {
                nextPage(page.selected + 1);
              }}
              containerClassName={"pagination"}
              activeClassName={"active"}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    superAdminHeartID: state.persist.superAdminHeartID,
  };
};

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  theader: {
    "& th": {
      fontSize: "1.4rem",
      fontWeight: "bold",
    },
  },
  row: {
    "& td": {
      fontSize: "1.2rem",
      fontWeight: 600,
      padding: "8px",
    },
    "& a": {
      color: theme.palette.common.black,
    },
  },
  redColor: {
    background: "red",
    color: theme.palette.common.black,
    "& a": {
      color: theme.palette.common.black,
    },
  },
  yellow: {
    background: "yellow",
    color: theme.palette.common.black,
    "& a": {
      color: theme.palette.common.black,
    },
  },
  white: {
    background: "white",
    color: theme.palette.common.white,
    "& a": {
      color: theme.palette.common.white,
    },
  },
}));

export default connect(mapStateToProps, null)(SuperAdminBloodOxygen);
