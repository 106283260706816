// import React, { useState, useEffect } from "react";
// import { useHistory } from "react-router-dom";
// import {
//   Button,
//   FormGroup,
//   FormControl,
//   ControlLabel,
//   InputGroup,
//   Row,
//   Col,
// } from "react-bootstrap";
// import { EyeFill } from "react-bootstrap-icons";
// import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
// import NavbarSuperAdmin from "../../components/navbar/NavbarSuperAdmin";
// import Axios from "axios";
// import { API_BASE_PATH } from "../../constants/workerConstants";
// import { toast } from "react-toastify";

// export default function AddAdmin(props) {
//   const [relations, setRelations] = useState([]);
//   const history = useHistory();
//   const [wait, setWait] = useState(false);
//   const [username, setUsername] = useState("");
//   const [firstName, setFirstName] = useState("");
//   const [lastName, setLastName] = useState("");
//   const [address, setAddress] = useState("");
//   const [phone, setPhone] = useState("");
//   const [networkName, setNetworkName] = useState("");
//   const [emailId, setEmailId] = useState("");
//   const [password, setPassword] = useState("");
//   const [showPassword, setShowPassword] = useState(false);
//   const [confirmPassword, setConfirmPassword] = useState("");
//   const [showConfirmPassword, setShowConfirmPassword] = useState(false);
//   const [quest, setQuest] = useState("");
//   const [answer, setAnswer] = useState("");
//   const [hospital, setHospital] = useState("");
//   const [hospitalList, setHospitalList] = useState([]);

//   //   const { userHasAuthenticated } = useAppContext();
//   const [disableSubmit, setDisableSubmit] = useState(false);

//   function validateForm() {
//     return (
//       emailId.length > 0 && password.length > 0 && password === confirmPassword
//     );
//   }

//   //get hospital list
//   const getHospitalList = () => {
//     Axios.get(`${API_BASE_PATH}/user/hospitalList`, {
//       headers: {
//         "content-type": "application/json",
//       },
//     })
//       .then((res) => {
//         console.log("res", res.data.result.list);

//         setHospitalList(res.data.result.list);
//       })
//       .catch((err) => {
//         console.log("hospital list api failed");
//       });
//   };

//   React.useEffect(() => {
//     getHospitalList();
//   }, []);

//   // submit form
//   const submitForm = () => {
//     // debugger;
//     const data = {
//       firstName: firstName,
//       lastName: lastName,
//       phno: phone,
//       emailId: emailId,
//       username: username,
//       relationship: null,
//       network: null,
//       networkName: `${firstName} family`,
//       address: address,
//       nickName: username,
//       securityQuestion: quest,
//       answer: answer,
//       password: password,
//       userRole: 0,
//       deviceType: "WEB",
//       hospital: hospital,
//     };

//     if (
//       username.trim() !== "" &&
//       firstName.trim() !== "" &&
//       lastName.trim() !== "" &&
//       phone.trim() !== "" &&
//       emailId.trim() !== "" &&
//       password.trim() !== "" &&
//       address.trim() !== "" &&
//       answer.trim() !== "" &&
//       hospital.trim() !== ""
//     ) {
//       if (
//         !String(emailId)
//           .toLowerCase()
//           .match(
//             /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
//           )
//       ) {
//         toast.error("Enter Correct Email Id ");
//         return;
//       }
//       if (isNaN(phone)) {
//         console.log(typeof phone);
//         toast.error("Enter Valid Phone Number");
//         return;
//       }
//       if (!isValidPhoneNumber(phone)) {
//         toast.error("Phone Number is not valid", {
//           toastId: "cell_number_error",
//         });
//         return;
//       }

//       if (password.length <= 5) {
//         toast.error("Password Should be atleast 6 character");
//         return;
//       }
//       if (confirmPassword.length <= 5) {
//         toast.error("Confirm Password Should be atleast 6 character");
//         return;
//       }
//       if (password === confirmPassword) {
//         Axios.post(`${API_BASE_PATH}/user/register`, data, {
//           headers: {
//             "content-type": "application/json",
//           },
//         })
//           .then((res) => {
//             history.push("/dashboard");
//             toast.info("Admin added successfully");
//           })
//           .catch((err) => {
//             // alert(err.response.data.responseMessage);
//             toast.error(err.response.data.responseMessage);
//             console.log("error in register api -- add admin");
//           });
//       } else {
//         toast.error("Password does not match");
//       }
//     } else {
//       if (firstName.trim() == "") {
//         toast.error("Enter First Name");
//         return;
//       }
//       if (lastName.trim() == "") {
//         toast.error("Enter Last Name");
//         return;
//       }
//       if (username.trim() == "") {
//         toast.error("Enter Username");
//         return;
//       }
//       if (emailId.trim() == "") {
//         toast.error("Enter Email Id ");
//         return;
//       } else {
//         if (
//           !String(emailId)
//             .toLowerCase()
//             .match(
//               /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
//             )
//         ) {
//           toast.error("Enter Correct Email Id ");
//           return;
//         }
//       }

//       if (phone.trim() == "") {
//         toast.error("Enter Phone Number");
//         return;
//       }
//       if (address.trim() == "") {
//         toast.error("Enter Address");
//         return;
//       }
//       if (quest.trim() == "") {
//         toast.error("Select Security Question");
//         return;
//       }

//       if (answer.trim() == "") {
//         toast.error("Enter Security Answer");
//         return;
//       }
//       if (password.trim() == "") {
//         toast.error("Enter Password");
//         return;
//       }
//       if (confirmPassword.trim() == "") {
//         toast.error("Confirm Password");
//         return;
//       }
//       if (password.length <= 5) {
//         toast.error("Password Should be atleast 6 character");
//         return;
//       }
//       if (confirmPassword.length <= 5) {
//         toast.error("Confirm Password Should be atleast 6 character");
//         return;
//       }
//       if (password !== confirmPassword) {
//         toast.error("Password does not match");
//         return;
//       }
//       if (hospital.trim() == "") {
//         toast.error("Select Organization Name");
//         return;
//       }
//     }
//   };

//   function toggleShow(field, value) {
//     if (field === "password") setShowPassword(!value);
//     else {
//       setShowConfirmPassword(!value);
//     }
//   }

//   return (
//     <div>
//       <NavbarSuperAdmin />
//       <div style={{ padding: "72px 280px" }}>
//         <form style={{ marginTop: "30px" }}>
//           <h5 style={{ fontWeight: 700, color: "#303030" }}>ADMIN FORM</h5>
//           <br />
//           <br />
//           {/* {test} */}
//           <Row
//             style={{
//               background: "#ECECEC",
//               padding: "1rem",
//               marginTop: "-2rem",

//               borderRadius: " 5px",
//             }}
//           >
//             <Col md={6}>
//               <FormGroup controlId="firstName" bsSize="large">
//                 <ControlLabel style={{ marginBottom: "10px" }}>
//                   {/* <b>First Name</b> */}
//                   <span style={{ color: "#303030" }}>First Name</span>
//                   <b style={{ color: "red" }}>*</b>
//                 </ControlLabel>
//                 <FormControl
//                   type="text"
//                   value={firstName}
//                   onChange={(e) => setFirstName(e.target.value)}
//                   required={true}
//                   style={{
//                     marginBottom: "12px",
//                     background: "#FFFFFF",
//                     border: "none",
//                     // border: "0px 0px 1px 0px solid #CBCACA",
//                     boxShadow: "0px 2px 1px rgb(0 0 0 /25%)",
//                     borderRadius: " 11px",
//                     // borderStyle: " 1px #CBCACA",
//                   }}
//                 />
//               </FormGroup>
//             </Col>
//             <Col md={6}>
//               <FormGroup controlId="lastName" bsSize="large">
//                 <ControlLabel style={{ marginBottom: "10px" }}>
//                   <span style={{ color: "#303030" }}>Last Name</span>
//                   <b style={{ color: "red" }}>*</b>
//                 </ControlLabel>
//                 <FormControl
//                   type="text"
//                   value={lastName}
//                   onChange={(e) => setLastName(e.target.value)}
//                   required={true}
//                   style={{
//                     marginBottom: "12px",
//                     background: "#FFFFFF",
//                     border: "none",
//                     // border: "0px 0px 1px 0px solid #CBCACA",
//                     boxShadow: "0px 2px 1px rgb(0 0 0 /25%)",
//                     borderRadius: " 11px",
//                   }}
//                 />
//               </FormGroup>
//             </Col>
//             <Col md={6}>
//               <FormGroup controlId="firstName" bsSize="large">
//                 <ControlLabel style={{ marginBottom: "10px" }}>
//                   <span style={{ color: "#303030" }}>Username</span>
//                   <b style={{ color: "red" }}>*</b>
//                 </ControlLabel>
//                 <FormControl
//                   autoFocus
//                   type="text"
//                   value={username}
//                   onChange={(e) => setUsername(e.target.value)}
//                   required={true}
//                   style={{
//                     marginBottom: "12px",
//                     background: "#FFFFFF",
//                     border: "none",
//                     // border: "0px 0px 1px 0px solid #CBCACA",
//                     boxShadow: "0px 2px 1px rgb(0 0 0 /25%)",
//                     borderRadius: " 11px",
//                   }}
//                   required
//                 />
//               </FormGroup>
//             </Col>
//             <Col md={6}>
//               <FormGroup controlId="emailId" bsSize="large">
//                 <ControlLabel style={{ marginBottom: "10px" }}>
//                   <span style={{ color: "#303030" }}>Email</span>
//                   <b style={{ color: "red" }}>*</b>
//                 </ControlLabel>
//                 <FormControl
//                   type="email"
//                   value={emailId}
//                   onChange={(e) => setEmailId(e.target.value)}
//                   required={true}
//                   style={{
//                     marginBottom: "12px",
//                     background: "#FFFFFF",
//                     border: "none",
//                     // border: "0px 0px 1px 0px solid #CBCACA",
//                     boxShadow: "0px 2px 1px rgb(0 0 0 /25%)",
//                     borderRadius: " 11px",
//                   }}
//                 />
//               </FormGroup>{" "}
//             </Col>

//             <Col md={6} style={{ marginTop: "1rem" }}>
//               <FormGroup controlId="phone" bsSize="large">
//                 <ControlLabel style={{ marginBottom: "10px" }}>
//                   <span style={{ color: "#303030" }}>Phone Number</span>
//                   <b style={{ color: "red" }}>*</b>
//                 </ControlLabel>

//                 <PhoneInput
//                   value={phone}
//                   onChange={setPhone}
//                   defaultCountry="US"
//                 />
//               </FormGroup>
//             </Col>
//             <Col md={6} style={{ marginTop: "1rem" }}>
//               <FormGroup controlId="address" bsSize="large">
//                 <ControlLabel style={{ marginBottom: "10px" }}>
//                   <span style={{ color: "#303030" }}>Address</span>
//                   <b style={{ color: "red" }}>*</b>
//                 </ControlLabel>
//                 <FormControl
//                   type="text"
//                   value={address}
//                   onChange={(e) => setAddress(e.target.value)}
//                   required={true}
//                   style={{
//                     marginBottom: "12px",
//                     borderRadius: " 11px",
//                     background: "#FFFFFF",
//                     border: "none",
//                     // border: "0px 0px 1px 0px solid #CBCACA",
//                     boxShadow: "0px 2px 1px rgb(0 0 0 /25%)",
//                   }}
//                 />
//               </FormGroup>
//             </Col>

//             <Col md={6} style={{ marginTop: "1rem" }}>
//               <FormGroup controlId="quest" bsSize="large">
//                 <ControlLabel style={{ marginBottom: "10px" }}>
//                   <span style={{ color: "#303030" }}>Security Question</span>
//                   <b style={{ color: "red" }}>*</b>
//                 </ControlLabel>
//                 <FormControl
//                   componentClass="select"
//                   value={quest}
//                   // onChange={e => setQuest(e.target.value)
//                   onChange={(e) => {
//                     setQuest(e.target.value);
//                     console.log(e.target.value);
//                   }}
//                   required={true}
//                   style={{
//                     marginBottom: "12px",
//                     borderRadius: " 11px",
//                     background: "#FFFFFF",
//                     border: "none",
//                     // border: "0px 0px 1px 0px solid #CBCACA",
//                     boxShadow: "0px 2px 1px rgb(0 0 0 /25%)",
//                   }}
//                 >
//                   <option key="0"></option>
//                   <option key="1" value="What city were you born in?">
//                     What city were you born in?
//                   </option>
//                   <option key="2" value="What is your favorite food?">
//                     What is your favorite food?
//                   </option>
//                   <option key="3" value="What street did you grow up on?">
//                     What street did you grow up on?
//                   </option>
//                   <option key="4" value="What is your mother’s maiden name?">
//                     What is your mother’s maiden name?
//                   </option>
//                   <option key="5" value="Where did you meet your spouse?">
//                     Where did you meet your spouse?
//                   </option>
//                   <option key="6" value="What Is your favorite book?">
//                     What Is your favorite book?
//                   </option>
//                 </FormControl>
//               </FormGroup>
//             </Col>

//             <Col md={6} style={{ marginTop: "1rem" }}>
//               <FormGroup controlId="answer" bsSize="large">
//                 <ControlLabel style={{ marginBottom: "10px" }}>
//                   <span style={{ color: "#303030" }}>Security Answer</span>
//                   <b style={{ color: "red" }}>*</b>
//                 </ControlLabel>
//                 <FormControl
//                   type="text"
//                   value={answer}
//                   onChange={(e) => setAnswer(e.target.value)}
//                   required={true}
//                   style={{
//                     marginBottom: "12px",
//                     borderRadius: " 11px",
//                     background: "#FFFFFF",
//                     border: "none",

//                     boxShadow: "0px 2px 1px rgb(0 0 0 /25%)",
//                   }}
//                 />
//               </FormGroup>
//             </Col>
//             <Col md={6}>
//               <FormGroup controlId="password" bsSize="large">
//                 <ControlLabel style={{ marginBottom: "10px" }}>
//                   <span style={{ color: "#303030" }}>Password</span>
//                   <b style={{ color: "red" }}>*</b>
//                 </ControlLabel>

//                 <InputGroup style={{ position: "relative" }}>
//                   <FormControl
//                     type={showPassword ? "text" : "password"}
//                     value={password}
//                     onChange={(e) => setPassword(e.target.value)}
//                     required={true}
//                     style={{
//                       borderRadius: " 11px",
//                       background: "#FFFFFF",
//                       border: "none",
//                       position: "relative",
//                       paddingRight: "2rem",
//                       // border: "0px 0px 1px 0px solid #CBCACA",
//                       boxShadow: "0px 2px 1px rgb(0 0 0 /25%)",
//                     }}
//                   />
//                   <InputGroup.Addon
//                     style={{
//                       // padding: "6px",
//                       borderLeft: "0",
//                       verticalAlign: "middle",
//                       // border: "1px solid #ccc"
//                       textAlign: "center",
//                       // background: "#FFFFFF",
//                       position: "absolute",
//                       zIndex: "9999",
//                       bottom: "0.5rem",
//                       right: "0.6rem",
//                     }}
//                     onClick={() => {
//                       toggleShow("password", showPassword);
//                     }}
//                   >
//                     <EyeFill />
//                   </InputGroup.Addon>
//                 </InputGroup>
//               </FormGroup>
//             </Col>
//             {/* <Col md={4}></Col> */}
//             <Col md={6}>
//               <FormGroup controlId="confirmPassword" bsSize="large">
//                 <ControlLabel style={{ marginBottom: "10px" }}>
//                   <span style={{ color: "#303030" }}>Confirm Password</span>
//                   <b style={{ color: "red" }}>*</b>
//                 </ControlLabel>
//                 <InputGroup>
//                   <FormControl
//                     type={showConfirmPassword ? "text" : "password"}
//                     onChange={(e) => setConfirmPassword(e.target.value)}
//                     value={confirmPassword}
//                     required={true}
//                     style={{
//                       borderRadius: " 11px",
//                       background: "#FFFFFF",
//                       border: "none",
//                       paddingRight: "2rem",
//                       // border: "0px 0px 1px 0px solid #CBCACA",
//                       boxShadow: "0px 2px 1px rgb(0 0 0 /25%)",
//                     }}
//                     // style={{ marginBottom: "12px" }}
//                   />
//                   <InputGroup.Addon
//                     style={{
//                       // padding: "6px",
//                       borderLeft: "0",
//                       verticalAlign: "middle",
//                       // border: "1px solid #ccc"
//                       textAlign: "center",
//                       // background: "#FFFFFF",
//                       position: "absolute",
//                       zIndex: "9999",
//                       bottom: "0.5rem",
//                       right: "0.6rem",
//                     }}
//                     onClick={() => {
//                       toggleShow("confirm_password", showConfirmPassword);
//                     }}
//                   >
//                     <EyeFill />
//                   </InputGroup.Addon>
//                 </InputGroup>
//               </FormGroup>
//             </Col>

//             <Col md={12} style={{ marginTop: "1rem" }}>
//               <FormGroup controlId="hospital" bsSize="large">
//                 <ControlLabel style={{ marginBottom: "10px" }}>
//                   <span style={{ color: "#303030" }}>
//                     Choose Organization Name
//                   </span>
//                   <b style={{ color: "red" }}>*</b>
//                 </ControlLabel>
//                 <FormControl
//                   componentClass="select"
//                   value={hospital}
//                   onChange={(e) => {
//                     setHospital(e.target.value);
//                     // console.log(e.target.value)
//                   }}
//                   required={true}
//                   style={{
//                     marginBottom: "12px",
//                     borderRadius: " 11px",
//                     background: "#FFFFFF",
//                     border: "none",
//                     // border: "0px 0px 1px 0px solid #CBCACA",
//                     boxShadow: "0px 2px 1px rgb(0 0 0 /25%)",
//                   }}
//                 >
//                   <option value="Select Hospital">
//                     Select Organization Name
//                   </option>
//                   {hospitalList.map((item, index) => {
//                     return (
//                       <option key={index} value={item._id}>
//                         {item.hospitalName}
//                       </option>
//                     );
//                   })}
//                 </FormControl>
//               </FormGroup>
//             </Col>
//             <div className="text-center mt-3 ">
//               <Button
//                 onClick={() => submitForm()}
//                 className="btn "
//                 bsSize="medium"
//                 style={{
//                   backgroundColor: "rgb(29, 117, 189)",
//                   color: "rgb(255, 255,255)",
//                   // marginLeft: "39rem",
//                   borderRadius: "12px",
//                   padding: "0.3rem 3rem 0.3rem 3rem",
//                 }}
//               >
//                 Save
//               </Button>
//             </div>
//           </Row>
//         </form>
//       </div>
//     </div>
//   );
// }

import React from "react";
import { connect } from "react-redux";
import NavbarSuperAdmin from "../../components/navbar/NavbarSuperAdmin";
import { GoToBack } from "../../components/ui/GoToBack header/GoToBack";
import SignUp from "../auth/AddAdminForm/AddAdminForm";

const AddAdmin = (props) => {
  console.log("AddAdmin");
  return (
    <div>
      <NavbarSuperAdmin />

      <div className="pl">
        <GoToBack>Add Admin</GoToBack>
        <SignUp
          isTitle={false}
          networkID={props.userData.network._id}
          isSuperAdmin={true}
          isAdd={true}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.persist.userData,
  };
};

export default connect(mapStateToProps)(AddAdmin);
