import React from "react";
import { connect } from "react-redux";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import { Phone } from "@material-ui/icons";
import { Link, useHistory } from "react-router-dom";
import SuperAdminLayout from "../../components/partials/SuperAdminLayout";
import { AppActionTypes } from "../../redux/action-types";

const tableColumns = ["User Name", "First Name", "Last Name", "Role", "Call"];
const roles = {
  0: "Admin",
  1: "Senior",
  2: "Care Giver",
  3: "Super Admin",
  4: "Doctor",
};

const PatientDashboard = (props) => {
  const history = useHistory();

  console.log("PatientDashboard props", props.memberList.list);

  const cameraTest = (row) => {
    props.setCallRow(row);
    history.push({
      pathname: `/dashboard/member-call`,
    });
  };

  return (
    <>
      <SuperAdminLayout>
        <div>
          <React.Fragment>
            <Table
              style={{
                // marginLeft: "166px",
                // width: "80%",
                marginBottom: "72px",
                marginTop: "30px",
              }}
            >
              <TableHead>
                <h4 style={{ marginTop: "10px", marginBottom: "25px" }}>
                  MEMBERS'S LIST
                </h4>

                <TableRow>
                  {tableColumns.map((item, i) => (
                    <TableCell align={true ? "left" : "center"}>
                      <b style={{ fontSize: "14px" }}>{item}</b>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              {props?.memberList?.list?.map((item, i) => (
                <TableBody align={"left"}>
                  <TableCell align={"left"}>
                    <Link
                      to={`/member-profile/${item._id}`}
                      // to={`/dashboard/member-details/${item._id}`}
                    >
                      <b style={{ fontSize: "14px" }}>{item.emailId}</b>
                    </Link>
                  </TableCell>

                  <TableCell align={"left"}>
                    <b style={{ fontSize: "14px" }}>{item.firstName}</b>
                  </TableCell>

                  <TableCell align={"left"}>
                    <b style={{ fontSize: "14px" }}>{item.lastName}</b>
                  </TableCell>

                  <TableCell align={"left"}>
                    <b style={{ fontSize: "14px" }}>{roles[item?.userRole]}</b>
                  </TableCell>

                  <TableCell align="left" style={{ fontSize: "12px" }}>
                    <Phone
                      onClick={() => {
                        cameraTest(item);
                      }}
                      style={{ cursor: "pointer" }}
                    />
                  </TableCell>
                </TableBody>
              ))}
            </Table>
            <ToastContainer />
          </React.Fragment>
        </div>
      </SuperAdminLayout>
    </>
  );
};

// export default PatientDashboard;

const mapStateToProps = (state) => {
  return {
    authToken: state.persist.authToken,
    userData: state.persist.userData,
    memberList: state.memberListReducer.memberList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCallRow: (row) =>
      dispatch({
        type: AppActionTypes.SET_CALL_ROW,
        payload: row,
      }),

    removeCallRow: () =>
      dispatch({
        type: AppActionTypes.REMOVE_CALL_ROW,
      }),
    dispatchDashboardID: (id) =>
      dispatch({
        type: AppActionTypes.SET_DASHBOARD_HEART_RATE_ID,
        id,
      }),

    getMemberList: (data) =>
      dispatch({
        type: "memberList/data",
        payload: data,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientDashboard);
