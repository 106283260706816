import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useParams, useHistory } from "react-router-dom";
import NavbarSuperAdmin from "../../components/navbar/NavbarSuperAdmin";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { API_BASE_PATH } from "../../constants/workerConstants";
import ReactPaginate from "react-paginate";

import { connect } from "react-redux";
import Axios from "axios";
import { HeartRate } from "../../components/chart/HeartRate";

const SuperAdminHeartRate = (props) => {
  const classes = useStyles();

  const [data, setData] = useState([]);
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");
  const { id } = useParams();
  const [networkAdmin, setNetworkAdmin] = useState("");
  const history = useHistory();
  const [totalPages, setTotalPages] = React.useState([]);
  const [activePage, setActivePage] = React.useState(1);

  useEffect(() => {
    if (props.superAdminHeartID) {
      fetchData();
    }

    async function fetchData() {
      let pages = [];

      const userData = localStorage.getItem("userId");
      // console.log(userData);
      const res = await fetch(`${API_BASE_PATH}/health/getHeartRate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          _id: props.superAdminHeartID,
          page: 1,
          limit: "40",
        }),
      });
      const response = await res.json();

      setRows(response.result.heartRate);
      console.log(response.result);

      if (response.result.totalPage) {
        for (let i = 1; i <= response.result.totalPage; i++) {
          pages = [...pages, i];
        }

        setTotalPages(pages);
      }
    }
  }, [props.superAdminHeartID]);

  const nextPage = (data) => {
    setActivePage(data);
    Axios.post(
      `${API_BASE_PATH}/health/getHeartRate`,
      {
        _id: props.superAdminHeartID,
        page: data,
        limit: "40",
      },
      {
        headers: {
          "content-type": "application/json",
        },
      }
    ).then((res) => {
      setRows(res.data.result.heartRate);
    });
  };

  function getColorClass(type, data) {
    switch (type) {
      case "heartRate":
        return data > 100 ? classes.redColor : data < 60 ? classes.yellow : "";
      default:
        return "";
    }
  }

  function handleSearch(e) {
    const { value } = e.target;
    setSearch(value);
  }

  return (
    <>
      <NavbarSuperAdmin />

      <div style={{ position: "relative" }}>
        <Table
          style={{
            marginTop: "70px",
            marginBottom: "100px",
            marginLeft: "183px",
            width: "77%",
          }}
        >
          <TableHead>
            <div class="header" style={{ marginTop: "0px" }}>
              <KeyboardBackspaceIcon
                onClick={() => window.history.back()}
                style={{
                  marginTop: "70px",
                  marginLeft: "-7px",
                  height: "40px",
                  width: "40px",
                }}
              />
              <h4
                style={{
                  position: "relative",
                  marginTop: "-34px",
                  marginLeft: "40px",
                  width: "100%",
                }}
              >
                <b>Heart Rate</b>
              </h4>
            </div>

          </TableHead>
          <TableBody>
           <HeartRate data={rows} />
          </TableBody>
        </Table>
        <div id={"react-paginate"} className="bottom-navi-custom">
          {totalPages !== null && totalPages.length ? (
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={totalPages.length}
              marginPagesDisplayed={2}
              pageClassName={"navi-li"}
              pageRangeDisplayed={2}
              onPageChange={(page) => {
                nextPage(page.selected + 1);
              }}
              containerClassName={"pagination"}
              activeClassName={"active"}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    superAdminHeartID: state.persist.superAdminHeartID,
  };
};

export default connect(mapStateToProps)(SuperAdminHeartRate);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  theader: {
    "& th": {
      fontSize: "1.4rem",
      fontWeight: "bold",
    },
  },
  row: {
    "& td": {
      fontSize: "1.2rem",
      fontWeight: 600,
      padding: "8px",
    },
    "& a": {
      color: theme.palette.common.black,
    },
  },
  redColor: {
    background: "red",
    color: theme.palette.common.white,
    "& a": {
      color: theme.palette.common.white,
    },
  },
  yellow: {
    background: "yellow",
    color: theme.palette.common.black,
    "& a": {
      color: theme.palette.common.black,
    },
  },
}));
