import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import NavbarSuperAdmin from "../../../components/navbar/NavbarSuperAdmin";
import { TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { GoToBack } from "../../../components/ui/GoToBack header/GoToBack";
import { Table } from "react-bootstrap";
import { API_BASE_PATH } from "../../../constants/workerConstants";
import { toast } from "react-toastify";
import axios from "axios";
import { FadeLoader } from "react-spinners";

const CareGiversList = (props) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();

  async function getData() {
    try {
      setIsLoading(true);
      const { data } = await axios.get(
        `${API_BASE_PATH}/user/caregiver?seniorID=${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: props.authToken,
          },
        }
      );

      console.log("fetchCareGiversList:", data);
      setIsLoading(false);
      setData(data.data);
    } catch (err) {
      toast.error(err.message);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(getData, []);

  return (
    <div>
      <NavbarSuperAdmin />

      <div className="pl">
        <GoToBack>Care Givers List</GoToBack>
        <div style={{ maxWidth: "800px", width: "100%", margin: "auto" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <b style={{ fontSize: "14px" }}>Name</b>
                </TableCell>
                <TableCell align="center">
                  <b style={{ fontSize: "14px" }}>Phone Number</b>
                </TableCell>
                <TableCell align="center">
                  <b style={{ fontSize: "14px" }}>Email ID</b>
                </TableCell>
              </TableRow>
            </TableHead>
            {data.length === 0 ? (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={4}>
                    <div
                      className="text-center mt-5"
                      style={{
                        backgroundColor: isLoading
                          ? "rgb(29 117 189 / 85%)"
                          : "rgb(29, 117, 189)",
                        color: "rgb(255, 255, 255)",
                        padding: "0.5rem 0",
                        borderRadius: "5px",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        height: isLoading ? "40px" : "auto",
                      }}
                    >
                      {!isLoading && "No Data Available"}

                      <FadeLoader
                        height={10}
                        loading={isLoading}
                        margin={-7}
                        speedMultiplier={3}
                        width={4}
                        color="#fff"
                      />
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {data.map((row) => (
                  <TableRow key={row._id}>
                    <TableCell
                      align="center"
                      style={{
                        fontSize: "13.5px",
                        padding: "16px 2px",
                      }}
                    >
                      <b style={{ color: "black" }}>{row.firstName}</b>
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ fontSize: "13.5px", padding: "16px 2px" }}
                    >
                      <b>{row.phno}</b>
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ fontSize: "13.5px", padding: "16px 2px" }}
                    >
                      <b>{row.emailId}</b>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    memberList: state.memberListReducer.memberList,
    authToken: state.persist.authToken,
  };
};

export default connect(mapStateToProps)(CareGiversList);
