import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Box from "@material-ui/core/Box";
import { Paper, Container } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
// import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

import { useHistory, NavLink } from "react-router-dom";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { useParams, useLocation } from "react-router-dom";
import NavbarSuperAdmin from "../../components/navbar/NavbarSuperAdmin.jsx";
import CopyRight from "../../components/elements/copyright/CopyRight.js";
import Phone from "@material-ui/icons/Phone";
import { API_BASE_PATH } from "../../constants/workerConstants";
import { connect } from "react-redux";
import { AppActionTypes } from "../../redux/action-types";
import MyModal from "../../components/ui/app-modal/Modal";
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';

const ECG = {
  NORMAL: "Normal",
  IRREGULAR: "Irregular",
};

function randomNumberBetweenRange(min, max) {
  return Math.floor(Math.random() * (max - min) + 1) + min;
}

function transformMyData(data) {
  return data.map((item, index) => ({
    ...item,
    ecg: index % 3 ? ECG.NORMAL : ECG.IRREGULAR,
    heartRate: index % 4 === 0 ? 106 : randomNumberBetweenRange(70, 100),
    bloodPressure:
      index % 4 === 0
        ? `${randomNumberBetweenRange(155, 181)}/${randomNumberBetweenRange(
            80,
            90
          )}`
        : `${randomNumberBetweenRange(120, 155)}/${randomNumberBetweenRange(
            80,
            90
          )}`,
  }));
}

const SuperAdminHospitalDetail = (props) => {
  const classes = useStyles();
  // const [rows, setRows] = useState([]);
  const [rows, setRows] = useState({
    users: [],
    page: 10,
  });
  const [search, setSearch] = useState("");
  const [networkAdmin, setNetworkAdmin] = useState("");
  const [openModal, setOpenModal] = React.useState(false);
  const [userModalData,setUserModalData]=useState({});
  const location = useLocation();
  const history = useHistory();

  const { id } = useParams();
  console.log(id);

  console.log("rows", rows);

  useEffect(() => {
    if (props.hospitalId) {
      fetchData();
    }

    async function fetchData() {
      const userData = localStorage.getItem("userId");
      console.log(userData);
      const res = await fetch(`${API_BASE_PATH}/superAdmin/adminList`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          // hospital: JSON.parse(userData)
          hospital_id: props.hospitalId,
        }),
      }).then((res) => res.json());

      // const response = res.json();

      console.log("data", res);
      const myData = res.result.doctors.reverse();
      // res.result.pop(res.result.doctors.length-1)
      setRows({ users: myData, page: rows.page });
      // setRows(myData)
    }

    // if (wait === false && relations.length === 0) {
    //   fetchData();
    // }
  }, [props.hospitalId]);

  const viewMore = () => {
    setRows((prevState) => {
      return {
        page: prevState.page + 5,
        users: prevState.users,
      };
    });
    //   console.log(rows);
  };
  // let length = rows.users;
  function viewLess() {
    setRows((prevState) => {
      return {
        page: 5,
        users: prevState.users,
      };
    });
  }

  function handleSearch(e) {
    const { value } = e.target;
    setSearch(value);
  }
  const openModalFunc=(userData)=>{
    setUserModalData(userData);
    setOpenModal(true)

  }

  return (
    <div>
      <NavbarSuperAdmin />

      {console.log("Dev", location)}
      <div style={{ marginLeft: "0px" }}>
        <div>
          <div>
            <TextField
              variant="standard"
              label="Search Here"
              name="search"
              value={search}
              onChange={handleSearch}
              style={{
                float: "right",
                marginRight: "95px",
                marginTop: "-32px",
                width: "15%",
              }}
            />
          </div>
          <React.Fragment>
            <Table
              style={{
                marginTop: "130px",
                marginLeft: "183px",
                width: "77%",
              }}
            >
              <TableHead>
                <div class="header" style={{ marginTop: "0px" }}>
                  <KeyboardBackspaceIcon
                    onClick={() => window.history.back()}
                    style={{
                      marginTop: "70px",
                      marginLeft: "-7px",
                      height: "40px",
                      width: "40px",
                    }}
                  />
                  <h4
                    style={{
                      position: "relative",
                      marginTop: "-34px",
                      marginLeft: "40px",
                      width: "100%",
                    }}
                  >
                    <b>{props.hospitalName}</b>
                  </h4>
                </div>
                <TableRow>
                  <TableCell align="center">
                    <b style={{ fontSize: "14px" }}>Admin Name</b>
                  </TableCell>
                  <TableCell align="center">
                    <b style={{ fontSize: "14px" }}>First Name</b>
                  </TableCell>
                  <TableCell align="center">
                    <b style={{ fontSize: "14px" }}>Last Name</b>
                  </TableCell>
                  <TableCell align="center">
                    <b style={{ fontSize: "14px" }}>Phone Number</b>
                  </TableCell>
                  <TableCell align="center">
                    <b style={{ fontSize: "14px" }}>Email</b>
                  </TableCell>
                  { 
                  // rows?.users[0]?.securityQuestion &&
                  <TableCell align="center">
                    <b style={{ fontSize: "14px" }}>Security Question</b>
                  </TableCell>
                  }
                  {/*<TableCell align="center">*/}
                  {/*    <b style={{fontSize: "14px"}}>Call</b>*/}
                  {/*</TableCell>*/}
                </TableRow>
              </TableHead>
              {rows.users.length == 0 ? (
                <div
                  className="text-center mt-5 "
                  style={{
                    backgroundColor: "rgb(29, 117, 189)",
                    color: "rgb(255, 255,255)", 
                    padding: "0.5rem",
                    marginRight: "-45rem",
                  }}
                >
                  No Data Available
                </div>
              ) : (
                <TableBody>
                  {rows.users
                    .filter((i) =>
                      search.length > 0
                        ? i.firstName
                            .toLowerCase()
                            .startsWith(search.toLowerCase())
                        : i
                    )
                    .slice(0, rows.page)
                    .map((row) => (
                      <TableRow key={row.id}>
                        {/* <NavLink to={`/superadminhospitaldetail/${row._id}`}><TableCell style={{fontSize:"12px"}}><b>{row.hospitalName}</b></TableCell></NavLink>  */}
                        <TableCell
                          align="center"
                          style={{
                            fontSize: "12px",
                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                          onClick={() => {
                            props.setNetworkAdminId(row._id);
                            props.dispatchNestedNetworkId(row.network._id);

                            history.push(
                              `/superadminhospitaldetail/patientdetail`
                            );
                          }}
                        >
                          <b style={{ color: "black" }}>{row.username}</b>
                        </TableCell>
                        <TableCell align="center" style={{ fontSize: "12px" }}>
                          <b>{row.firstName}</b>
                        </TableCell>
                        <TableCell align="center" style={{ fontSize: "12px" }}>
                          <b>{row.lastName}</b>
                        </TableCell>
                        <TableCell align="center" style={{ fontSize: "12px" }}>
                          <b>{row.phno}</b>
                        </TableCell>
                        <TableCell align="center" style={{ fontSize: "12px" }}>
                          <b>{row.emailId}</b>
                        </TableCell>
                        {/* <b>{row.emailId}</b> */}
                        {
                        // row?.securityQuestion &&
                          <TableCell align="center" style={{ fontSize: "12px" }}>
                          <span style={{cursor:'pointer'}} onClick={()=>openModalFunc(row)}><InfoIcon /></span>
                        </TableCell>
                        }
                        {/*<TableCell align="center" style={{fontSize: "12px"}}>*/}
                        {/*    <Phone onClick={() => history.push({*/}
                        {/*        pathname: `/superadminhospitaldetail/patient-call`,*/}
                        {/*        state: row*/}
                        {/*    })} style={{cursor: 'pointer'}}/>*/}
                        {/*</TableCell>*/}
                      </TableRow>
                    ))}
                  {
                    /* <button
                  className="btn btn-primary text-center"
                  onClick={viewMore}
                >
                  View More
                </button> */
                    <div
                      className="text-center mt-5"
                      style={{ marginRight: "-45rem" }}
                    >
                      {rows.page >= rows.users.length &&
                      rows.users.length > 5 ? (
                        <div>
                          <button
                            className="btn btn-warning "
                            onClick={viewLess}
                          >
                            View Less
                          </button>
                        </div>
                      ) : (
                        <div>
                          {rows.users.length > 5 ? (
                            <button
                              className="btn"
                              style={{
                                backgroundColor: "rgb(29, 117, 189)",
                                color: "rgb(255, 255,255)",
                              }}
                              onClick={viewMore}
                            >
                              View More
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                    </div>
                  }
                </TableBody>
              )}
            </Table>
            <MyModal open={openModal} showHalf={true}>
            <CloseIcon style={{float:'right',cursor:'pointer'}} onClick={()=>setOpenModal(false)}/>
            
            <h4 style={{textAlign:'center'}}>Admin Details</h4>
            <br></br>
            <div style={{display:'flex',justifyContent:'center'}}>
            <div>
                  <p style={{fontWeight:'bold'}}>Emai Id : {userModalData.emailId}</p>
                  <p style={{fontWeight:'bold'}}>User Name : {userModalData.username}</p>
                  <p style={{fontWeight:'bold'}}>Security Question : {userModalData.securityQuestion}</p>
                  <p style={{fontWeight:'bold'}}>Security Answer : {userModalData.answer}</p>
              </div>
              </div>
            </MyModal>
            {/* <div className={classes.seeMore}>
        <Link color="primary" to="/patientdetail" >
          view More
        </Link>
      </div> */}
          </React.Fragment>
        </div>

        <Box pt={4}>
          <CopyRight />
        </Box>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    hospitalId: state.persist.hospitalId,
    hospitalName: state.persist.hospitalName,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setNetworkAdminId: (id) =>
      dispatch({
        type: AppActionTypes.SET_NETWORK_ADMIN_ID,
        id,
      }),
    dispatchNestedNetworkId: (id) =>
      dispatch({
        type: AppActionTypes.SET_SUPER_ADMIN_NETWORK_NESTED_ID,
        payload: id,
      }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SuperAdminHospitalDetail);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  theader: {
    "& th": {
      fontSize: "1.4rem",
      fontWeight: "bold",
    },
  },
  row: {
    "& td": {
      fontSize: "1.2rem",
      fontWeight: 600,
      padding: "6px",
    },
    "& a": {
      color: theme.palette.common.black,
    },
  },
  redColor: {
    background: "red",
    color: theme.palette.common.white,
    "& a": {
      color: theme.palette.common.white,
    },
  },
  yellow: {
    background: "yellow",
    color: theme.palette.common.black,
    "& a": {
      color: theme.palette.common.black,
    },
  },
}));
