import React from "react";
import { XLg, XSquare } from "react-bootstrap-icons";
import styles from "./DisplayMedia.module.css";

const DisplayMedia = ({ type, url, onClose }) => {
  return (
    <>
      <div className={styles.mediaDisplayContainer}>
        <button className={styles.closeBtn} onClick={onClose}>
          <XLg />
        </button>

        <div className={styles.mediaDisplayContent}>
          {type === "video" && (
            <video src={url} controls className={styles.Video}></video>
          )}

          {type === "image" && (
            <img
              className={styles.image}
              src={url}
              alt="media"
              onError={(e) => {
                e.target.classList.add("hide-media");
              }}
            />
          )}
        </div>
      </div>

      <div className={styles.mediaDisplayOverlay} onClick={onClose}></div>
    </>
  );
};

export default DisplayMedia;
