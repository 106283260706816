import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  Button,
} from "@material-ui/core";

function ConfirmDialog(props) {
  const { confirmDialog, setConfirmDialog } = props;
  return (
    <Dialog open={confirmDialog.isOpen}>
      <DialogTitle></DialogTitle>
      <DialogContent>
        <Typography variant="h6"> {confirmDialog.text}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          component="span"
          onClick={() => setConfirmDialog({ isOpen: false })}
        >
          No
        </Button>
        <Button
          variant="contained"
          component="span"
          onClick={confirmDialog.onConfirm}
          style={{ backgroundColor: "rgb(29, 117, 189)" }}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDialog;
