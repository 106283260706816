import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useHistory } from "react-router-dom";
import { API_BASE_PATH } from "../../constants/workerConstants";
import { connect } from "react-redux";
import Axios from "axios";
import Phone from "@material-ui/icons/Phone";
import Delete from "@material-ui/icons/Delete";
import { AppActionTypes } from "../../redux/action-types";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MyModal from "../../components/ui/app-modal/Modal";
import ConfirmDialog from "../../components/ui/app-modal/ConfirmDialog";
import PatientInfoDialog from "../../components/ui/app-modal/patientInfoDialog";
import WarningIcon from "@material-ui/icons/Warning";
import CloseIcon from "@material-ui/icons/Close";
import dateFormat from "dateformat";
import axios from "axios";
const ECG = {
  NORMAL: "Normal",
  IRREGULAR: "Irregular",
};

function transformMyData(data) {
  return data.map((item, index) => ({
    ...item,
  }));
}

const AdminPatientList = (props) => {
  const classes = useStyles();
  const [rows, setRows] = useState({
    users: [],
    page: 10,
  });
  const [openVitalModal, setOpenVitalModal] = React.useState(false);
  const [modalData, setModalData] = React.useState({});
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    text: "",
  });
  // const [rows, setRows] = useState([]);
  const [patientsInfo, setPatientInfo] = useState({ isOpen: false });
  const [search, setSearch] = useState("");
  const history = useHistory();
  const saveModalData = (data) => {
    setModalData(data);
    setOpenVitalModal(true);
  };
  React.useEffect(() => {
    if (props.userData) {
      getPatientList();
    }
  }, [props.userData]);

  const getPatientList = () => {
    Axios.post(`${API_BASE_PATH}/user/patientList`, {
      networkAdmin: props.userData._id,
    })
      .then((res) => {
        let myData = transformMyData(res.data.result);

        if (myData.length) {
          myData = myData.filter((data) => {
            // console.log("akshay__check", data._id !== props.userData._id);
            return data._id !== props.userData._id;
          });
          setRows({ users: myData, page: rows.page });
        }
      })
      .catch((err) => {
        console.log("err");
      });
  };

  const viewMore = () => {
    setRows((prevState) => {
      return {
        page: prevState.page + 5,
        users: prevState.users,
      };
    });
    //   console.log(rows);
  };

  const viewLess = () => {
    setRows((prevState) => {
      return {
        page: 5,
        users: prevState.users,
      };
    });
  };
  const cameraTest = (row) => {
    props.setCallRow(row);
    history.push({
      pathname: `/dashboard/patient-call`,
    });
  };

  function handleSearch(e) {
    const { value } = e.target;
    setSearch(value);
  }
  const onDelete = async (id) => {
    Axios.post(
      `${API_BASE_PATH}/user/removeSenior`,
      { id: id },
      {
        headers: {
          "Content-Type": "application/json",
          authorization: props.authToken,
        },
      }
    ).then((res) => {
      if (res.status === 200) {
        getPatientList();
        setConfirmDialog({ isOpen: false });
      }
    });
  };
  function getColorClass(type, data) {
    data = parseInt(data);
    switch (type) {
      case "heartRate":
        return data > 100 ? classes.redColor : data < 60 ? classes.yellow : "";
      case "bloodOxygen":
        return data < 88
          ? classes.redColor
          : data > 88 && data < 95
          ? classes.yellow
          : "";
      default:
        return "";
    }
  }

  return (
    <>
      <div style={{ marginLeft: "0px" }}>
        <React.Fragment>
          <Table
            style={{
              marginLeft: "166px",
              width: "80%",
              marginBottom: "72px",
              marginTop: "100px",
            }}
          >
            <TableHead>
              <h4 style={{ marginTop: "10px", marginBottom: "25px" }}>
                PATIENT'S LIST
              </h4>

              <TableRow>
                <TableCell align="left">
                  <b style={{ fontSize: "14px" }}>User Name</b>
                </TableCell>
                <TableCell align="center">
                  <b style={{ fontSize: "14px" }}>First Name</b>
                </TableCell>
                <TableCell align="center">
                  <b style={{ fontSize: "14px" }}>Last Name</b>
                </TableCell>
                <TableCell align="center">
                  <b style={{ fontSize: "14px" }}>Heart Rate</b>
                </TableCell>
                <TableCell align="center">
                  <b style={{ fontSize: "14px" }}>Step Count</b>
                </TableCell>
                <TableCell align="center">
                  <b style={{ fontSize: "14px" }}>Blood Oxygen</b>
                </TableCell>

                <TableCell align="center">
                  <b style={{ fontSize: "14px" }}>Vital Status</b>
                </TableCell>
                <TableCell align="center">
                  <b style={{ fontSize: "14px" }}>Call</b>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            {rows.users.length == 0 ? (
              <div
                className="text-center mt-5 "
                style={{
                  backgroundColor: "rgb(29, 117, 189)",
                  color: "rgb(255, 255,255)",
                  padding: "0.5rem",
                  marginRight: "-45rem",
                }}
              >
                No Data Available
              </div>
            ) : (
              <TableBody>
                {rows.users
                  .filter((i) =>
                    search.length > 0
                      ? i.firstName
                          .toLowerCase()
                          .startsWith(search.toLowerCase())
                      : i
                  )
                  .slice(0, rows.page)
                  .map((row) => {
                    return (
                      <TableRow key={row.id}>
                        {/* <NavLink to={`/superadminhospitaldetail/${row._id}`}><TableCell style={{fontSize:"12px"}}><b>{row.hospitalName}</b></TableCell></NavLink>  */}
                        <TableCell align="left" style={{ fontSize: "12px" }}>
                          <b style={{ color: "black" }}>
                            {row.username == null ? (
                              <div>{"-"}</div>
                            ) : (
                              <div
                                style={{
                                  cursor: "pointer",
                                  textDecoration: "underline",
                                }}
                              >
                                <div
                                  onClick={() => {
                                    history.push({
                                      pathname: `/dashboard/patient-details/${row._id}`,
                                    });
                                  }}
                                >
                                  {row.username}
                                </div>
                              </div>
                            )}
                          </b>
                        </TableCell>
                        <TableCell align="center" style={{ fontSize: "12px" }}>
                          <b>{row.firstName == null ? "-" : row.firstName}</b>
                        </TableCell>
                        <TableCell align="center" style={{ fontSize: "12px" }}>
                          <b>{row.lastName == null ? "-" : row.lastName}</b>
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            fontSize: "12px",
                          }}
                          className={getColorClass("heartRate", row.heartRate)}
                        >
                          <b>
                            {row.heartRate == null ? (
                              <div>{"-"}</div>
                            ) : (
                              <div
                                style={{
                                  cursor: "pointer",
                                  textDecoration: "underline",
                                }}
                              >
                                <div
                                  onClick={() => {
                                    props.dispatchDashboardID(row._id);
                                    history.push(
                                      "/admindashboard/adminheartrate"
                                    );
                                  }}
                                >
                                  {row.heartRate}
                                </div>
                              </div>
                            )}
                          </b>
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          <b>
                            {row.stepCount == null ? (
                              <div>{"-"}</div>
                            ) : (
                              <div
                                style={{
                                  cursor: "pointer",
                                  textDecoration: "underline",
                                }}
                              >
                                <div
                                  onClick={() => {
                                    props.dispatchDashboardID(row._id);
                                    history.push(
                                      "/admindashboard/adminstepcount"
                                    );
                                  }}
                                >
                                  {row.stepCount}
                                </div>
                              </div>
                            )}
                          </b>
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            fontSize: "12px",
                          }}
                          className={getColorClass(
                            "bloodOxygen",
                            row.bloodOxygen
                          )}
                        >
                          <b>
                            {row.bloodOxygen == null ? (
                              <div>{"-"}</div>
                            ) : (
                              <div
                                style={{
                                  cursor: "pointer",
                                  textDecoration: "underline",
                                }}
                              >
                                <div
                                  onClick={() => {
                                    props.dispatchDashboardID(row._id);
                                    history.push(
                                      "/admindashboard/adminbloodoxygen"
                                    );
                                  }}
                                >
                                  {row.bloodOxygen}
                                </div>
                              </div>
                            )}
                          </b>
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          {row?.whenSendVitalPush != null &&
                          row?.lastVitals != null ? (
                            <WarningIcon
                              style={{ color: "red", cursor: "pointer" }}
                              onClick={() => saveModalData(row)}
                            />
                          ) : !row.hasOwnProperty("whenSendVitalPush") &&
                            !row.hasOwnProperty("lastVitals") ? (
                            "-"
                          ) : (
                            <span
                              style={{ color: "green", fontWeight: "bold" }}
                            >
                              Active
                            </span>
                          )}
                        </TableCell>

                        <TableCell align="center" style={{ fontSize: "12px" }}>
                          <Phone
                            onClick={() => {
                              cameraTest(row);
                            }}
                            style={{ cursor: "pointer" }}
                          />
                        </TableCell>
                        <TableCell align="center" style={{ fontSize: "12px" }}>
                          <Delete
                            onClick={() => {
                              setConfirmDialog({
                                isOpen: true,
                                text: "Are you sure you want to delete",
                                onConfirm: () => {
                                  onDelete(row._id);
                                },
                              });
                            }}
                            style={{ cursor: "pointer", color: "red" }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {
                  <div
                    className="text-center mt-5"
                    style={{ marginRight: "-45rem" }}
                  >
                    {rows.page >= rows.users.length && rows.users.length > 5 ? (
                      <div>
                        <button className="btn btn-warning " onClick={viewLess}>
                          View Less
                        </button>
                      </div>
                    ) : (
                      <div>
                        {rows.users.length > 5 ? (
                          <button
                            className="btn"
                            style={{
                              backgroundColor: "rgb(29, 117, 189)",
                              color: "rgb(255, 255,255)",
                            }}
                            onClick={viewMore}
                          >
                            View More
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                  </div>
                }
              </TableBody>
            )}
          </Table>
          <ToastContainer />
        </React.Fragment>
        <MyModal open={openVitalModal} showHalf={true}>
          <CloseIcon
            style={{ float: "right", cursor: "pointer" }}
            onClick={() => setOpenVitalModal(false)}
          />
          <br></br>
          <h4 style={{ textAlign: "center" }}>Last Vital Recieved </h4>
          <h5 style={{ textAlign: "center" }}>
            {dateFormat(modalData.lastVitals, "mm/dd/yyyy hh:MM:ss")}
          </h5>
          <br></br>
        </MyModal>
        <PatientInfoDialog
          patientsInfo={patientsInfo}
          setPatientInfo={setPatientInfo}
        />
        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    authToken: state.persist.authToken,
    userData: state.persist.userData,
    memberList: state.memberListReducer.memberList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCallRow: (row) =>
      dispatch({
        type: AppActionTypes.SET_CALL_ROW,
        payload: row,
      }),

    removeCallRow: () =>
      dispatch({
        type: AppActionTypes.REMOVE_CALL_ROW,
      }),
    dispatchDashboardID: (id) =>
      dispatch({
        type: AppActionTypes.SET_DASHBOARD_HEART_RATE_ID,
        id,
      }),

    getMemberList: (data) =>
      dispatch({
        type: "memberList/data",
        payload: data,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminPatientList);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  theader: {
    "& th": {
      fontSize: "1.4rem",
      fontWeight: "bold",
    },
  },
  row: {
    "& td": {
      fontSize: "1.0rem",
      fontWeight: 600,
    },
    "& a": {
      color: theme.palette.common.black,
    },
  },
  redColor: {
    background: "red",
    color: theme.palette.common.white,
    "& a": {
      color: theme.palette.common.white,
    },
  },
  yellow: {
    background: "yellow",
    color: theme.palette.common.black,
    "& a": {
      color: theme.palette.common.black,
    },
  },
}));
