import React from "react";
import SuperAdminLayout from "../../components/partials/SuperAdminLayout";
import MediaList from "../../components/app/MediaComponents/MediaList/MediaList";
import MediaNav from "../../components/app/MediaComponents/MediaNav/MediaNav";
import { connect } from "react-redux";
import styles from "./MediaGalleryPage.module.css";

const data = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
];

const MediaGalleryPage = (props) => {
  console.log("props", props);
  return (
    <SuperAdminLayout>
      <h1 className={styles.heading}>Media Gallery</h1>

      <MediaList />
    </SuperAdminLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    authToken: state.persist.authToken,
  };
};

export default connect(mapStateToProps)(MediaGalleryPage);
