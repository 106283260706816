import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
        // width:props.showHalf?'50%':'100%'
    };
}

export default function MyModal(props) {
    const classes = useStyles();
    const [modalStyle,setModalStyle] = React.useState(getModalStyle);

    useEffect(()=>{
       if(props?.showHalf){
           let temp={...modalStyle};
           
           temp.width='30%';
           temp.minWidth='420px';
           temp.outline="none"
           setModalStyle(temp)
       }
    },[])
    return (
        <div>
            <Modal
                open={props.open}>
                <div style={modalStyle} className={classes.paper}>
                    {props.children}
                </div>
            </Modal>
        </div>
    );
}


const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 800,
        // height: 600,
        overflowY: "auto",
        backgroundColor: theme.palette.background.paper,
        padding: "20px 10px"
    },
}));