const initialState = {
  memberList: {},
};
const memberListReducer = (state = initialState, action) => {
  switch (action.type) {
    case "memberList/data":
      return { memberList: action.payload };
    default:
      return state;
  }
};
export default memberListReducer;
