import moment from "moment";

export const parseJwt = (token) => {
  if (token !== undefined) {
    var base64Url = token.split(".")[1];
    if (base64Url !== undefined) {
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
      return JSON.parse(jsonPayload);
    }
  }
  return undefined;
};

export const isValidEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const isValidPhone = (phone) => {
  const exp = /^[0-9]{6,16}$/;
  return exp.test(String(phone));
};

export const isValidPassword = (pwd) => {
  const expression =
    /^(?=.*\d)(?=.*[!@#$%^&*;_~>])(?=.*[a-z])(?=.*[A-Z]).{8,25}$/;
  return expression.test(String(pwd));
};

export const isValidContact = (phone) => {
  const exp = /^[0-9]{8,10}$/;
  return exp.test(String(phone));
};

export const createPageNumbersList = (
  pageBracketNumber,
  pagesPerBracket,
  maxPage
) => {
  let numbers = [];
  for (let i = 0; i < pagesPerBracket; i++) {
    let num = i + pagesPerBracket * (pageBracketNumber - 1) + 1;
    if (num <= maxPage) {
      numbers.push(num);
    } else {
      break;
    }
  }
  return numbers;
};

export const millisToDate = (milliSeconds) => {
  const intMilliSeconds = parseInt(milliSeconds) || 0;
  const localeDateTime = new Date(intMilliSeconds);
  return localeDateTime
    .toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    .replace(/(\d+)\/(\d+)\/(\d+)/g, "$3-$2-$1");
};

export const millisToTime = (milliSeconds) => {
  const intMilliSeconds = parseInt(milliSeconds) || 0;
  return new Date(intMilliSeconds).toISOString().substr(11, 8);
};

export const dateToMillis = (date) =>
  new Date(date).getTime() + new Date().getTimezoneOffset() * 60 * 1000;

export const timeToMillis = (time) => {
  const timeSplit = time.split(":");
  return (
    parseInt(timeSplit[0]) * 3600 * 1000 +
    parseInt(timeSplit[1]) * 60 * 1000 +
    parseInt(timeSplit[2] ? timeSplit[2] : "0") * 1000
  );
};

export const getMinimumDate = () =>
  new Date()
    .toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    .replace(/(\d+)\/(\d+)\/(\d+)/g, "$3-$2-$1");

export const SortBy = {
  DEFAULT: "DEFAULT",
  A_TO_Z: "A_TO_Z",
  NEWEST: "NEWEST",
};

export const sortObjectArray = (datarr, sortKey, isNumeric, isAsc) => {
  if (isNumeric) {
    if (isAsc) {
      datarr.sort((a, b) => {
        if (a[sortKey] > b[sortKey]) {
          return 1;
        } else if (a[sortKey] < b[sortKey]) {
          return -1;
        } else {
          return 0;
        }
      });
    } else {
      datarr.sort((a, b) => {
        if (a[sortKey] < b[sortKey]) {
          return 1;
        } else if (a[sortKey] > b[sortKey]) {
          return -1;
        } else {
          return 0;
        }
      });
    }
  } else {
    if (isAsc) {
      datarr.sort((a, b) => a[sortKey].localeCompare(b[sortKey]));
    } else {
      datarr.sort((a, b) => b[sortKey].localeCompare(a[sortKey]));
    }
  }
};

// Get Date
export const _getDateTimeFormat = (val, format) => {
  try {
    const date = moment(val).local();
    return date.format(format);
  } catch (error) {}
};

// Get Date
export const _getDateTime = (val, format) => {
  try {
    const date = moment.utc(val).local();
    return date.format(format);
  } catch (error) {}
};

// Get Date
export const _getDateTimeBST = (val, format) => {
  try {
    const date = moment.utc(val).subtract(1, "hours").local();
    return date.format(format);
  } catch (error) {}
};

// Get Time Date
export const _getDateTimeTimestamp = (val, format) => {
  try {
    const date = moment.unix(val).local().format(format);
    return date;
  } catch (error) {}
};

//Get Time Date
export const _dateTimeFormat = (val, format) => {
  try {
    return moment(val).format(format);
  } catch (error) {}
};
