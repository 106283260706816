import React from "react";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Typography from "@material-ui/core/Typography";
import DescriptionIcon from "@material-ui/icons/Description";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";

export default function AttachedMenu({ onCamera, onSelectMedia }) {
  const iconFields = [
    {
      text: "Document",
      icon: <DescriptionIcon fontSize="small" style={{ color: "#7f66ff" }} />,
      type: "application/pdf",
    },
    {
      text: "Photos & videos",
      icon: <PhotoLibraryIcon fontSize="small" style={{ color: "#007bfc" }} />,
      type: "image/png, image/jpg, image/jpeg, video/mp4, video/avi, video/mov",
    },
    {
      text: "Camera",
      icon: <PhotoCameraIcon fontSize="small" style={{ color: "#ff2e74" }} />,
      handleClick: onCamera,
    },
    {
      text: "Audios",
      icon: <VideoLibraryIcon fontSize="small" style={{ color: "#ffbc38" }} />,
      type: "audio/mp3",
    },
  ];

  const handleSelectMedia = (type) => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = type;
    input.onchange = (event) => {
      const file = event.target.files[0];
      const fileType = file.type.split("/")[0];
      const fileName = file.name;

      console.log("file", file);

      if (file.size > 20 * 1024 * 1024) {
        alert("File size exceeds 20MB. Please select a smaller file.");
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        onSelectMedia(fileType, base64String, fileName);
      };
      reader.readAsDataURL(file);
    };
    input.click();
  };

  return (
    <Paper>
      <MenuList>
        {iconFields.map((item) => (
          <MenuItem
            key={item.text}
            onClick={() => {
              if (item.type) {
                handleSelectMedia(item.type);
              } else {
                item.handleClick();
              }
            }}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <Typography variant="inherit">{item.text}</Typography>
          </MenuItem>
        ))}
      </MenuList>
    </Paper>
  );
}
