import React, { useEffect, useState } from "react";
import styles from "./PatientVitalsPage.module.css";
import SuperAdminLayout from "../../components/partials/SuperAdminLayout";
import { connect } from "react-redux";
import axios from "axios";
import { API_BASE_PATH } from "../../constants/workerConstants";
import { Link } from "react-router-dom";
import WarningIcon from "@material-ui/icons/Warning";
import MyModal from "../../components/ui/app-modal/Modal";
import CloseIcon from "@material-ui/icons/Close";
import dateFormat from "dateformat";

const skeleton = [1, 2, 3, 4];

const PatientVitalsPage = (props) => {
  const [patientData, setPatientData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [row, setRow] = useState(null);
  const [openVitalModal, setOpenVitalModal] = React.useState(false);
  const [modalData, setModalData] = React.useState({});

  console.log("row", row);

  useEffect(() => {
    getPatientList();
  }, []);

  const saveModalData = (data) => {
    if (row?.whenSendVitalPush != null && row?.lastVitals != null) {
      setModalData(data);
      setOpenVitalModal(true);
    }
  };

  const getPatientList = () => {
    setIsLoading(true);
    axios
      .post(`${API_BASE_PATH}/user/patientList`, {
        networkAdmin: props.userData._id,
      })
      .then((res) => {
        const userDetails = res.data?.result
          ?.filter((item) => item._id === props.userData._id)
          ?.pop();

        const vitalsData = [
          {
            title: "Heart Rate",
            link: "/heart-rate",
            data: userDetails.heartRate,
          },
          {
            title: "Step Count",
            link: "/step-count",
            data: userDetails.stepCount,
          },
          {
            title: "Blood Oxygen",
            link: "/blood-oxygen",
            data: userDetails.bloodOxygen,
          },
        ];
        setPatientData(vitalsData);
        setRow(userDetails);
      })
      .catch((err) => {
        console.log("err");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <SuperAdminLayout>
      <div className={styles.mainContainer}>
        <div className={styles.container}>
          {!isLoading && (
            <>
              {patientData &&
                patientData.map((item, i) => (
                  <Link key={i} to={`${item.link}`}>
                    <div className={styles.card}>
                      <p>{item.title}</p>
                      <p>{item.data}</p>
                    </div>
                  </Link>
                ))}

              <div onClick={() => saveModalData(row)} className={styles.card}>
                <p>Vital Status</p>

                {row &&
                  (row?.whenSendVitalPush != null && row?.lastVitals != null ? (
                    <WarningIcon style={{ color: "red", cursor: "pointer" }} />
                  ) : !row.hasOwnProperty("whenSendVitalPush") &&
                    !row.hasOwnProperty("lastVitals") ? (
                    "-"
                  ) : (
                    <span style={{ color: "green", fontWeight: "bold" }}>
                      Active
                    </span>
                  ))}
              </div>
            </>
          )}

          {isLoading &&
            skeleton.map((item, i) => (
              <div
                key={i}
                className={`${styles.card} ${styles.cardSkeleton} placeholder`}
              ></div>
            ))}
        </div>
      </div>

      <MyModal open={openVitalModal} showHalf={true}>
        <CloseIcon
          style={{ float: "right", cursor: "pointer" }}
          onClick={() => setOpenVitalModal(false)}
        />
        <br></br>
        <h4 style={{ textAlign: "center" }}>Last Vital Recieved </h4>
        <h5 style={{ textAlign: "center" }}>
          {dateFormat(modalData.lastVitals, "mm/dd/yyyy hh:MM:ss")}
        </h5>
        <br></br>
      </MyModal>
    </SuperAdminLayout>
  );
};

// export default PatientVitalsPage;

const mapStateToProps = (state) => {
  return {
    authToken: state.persist.authToken,
    userData: state.persist.userData,
    memberList: state.memberListReducer.memberList,
  };
};
export default connect(mapStateToProps)(PatientVitalsPage);
