import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import NavbarSuperAdmin from "../../../components/navbar/NavbarSuperAdmin";
import {
  Button,
  FormGroup,
  FormControl,
  ControlLabel,
  InputGroup,
} from "react-bootstrap";
import { GoToBack } from "../../../components/ui/GoToBack header/GoToBack";
import { Table } from "react-bootstrap";
import { API_BASE_PATH } from "../../../constants/workerConstants";
import { toast } from "react-toastify";
import axios from "axios";
import { FadeLoader } from "react-spinners";
import { MdEmail } from "react-icons/md";
import { IoPersonSharp } from "react-icons/io5";
import { MdContactPhone } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

const AddCareGiver = (props) => {
  const [userData, setUserData] = useState({
    email: "",
    name: "",
    phone: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const [patientData, setPatientData] = useState(null);

  // let patientData;

  useEffect(() => {
    if (props.memberList && props.memberList?.network?.members?.length) {
      const data = props.memberList.network.members.find((el) => el._id === id);
      data && setPatientData(data);
    }
  }, [props.memberList]);

  useEffect(() => {
    if (patientData) return;
    let data;

    if (props.userData && props.userData?.network?.members?.length) {
      data = props.userData.network.members.find(
        (el) => el._id === props.userData._id
      );
      data = {
        ...data,
        networkName: props.userData?.network?.networkName,
      };

      if (props.userData.hospital) {
        data = {
          ...data,
          hospital: props.userData.hospital.hospitalName,
        };
      }
      if (props.userData.relationship) {
        data = {
          ...data,
          relationship: props.userData.relationship.name,
        };
      }
      data && setPatientData(data);
    }
  }, [patientData, props.userData]);

  const inputFields = [
    {
      label: "Email Address",
      type: "text",
      name: "email",
      value: userData.email,
      placeholder: "Enter email address",
      icon: <MdEmail fontSize={20} />,
    },
    {
      label: "Name",
      type: "text",
      name: "name",
      value: userData.name,
      placeholder: "Enter name",
      icon: <IoPersonSharp fontSize={19} />,
    },
    {
      label: "Phone Number",
      type: "number",
      name: "phone",
      value: userData.phone,
      placeholder: "2156XXXXXX",
      icon: <FaPhoneAlt fontSize={17} />,
    },
  ];

  function handleOnPhoneChange(value) {
    setUserData((prev) => ({ ...prev, phone: value }));
  }

  function handleOnChange(e) {
    const { value, name } = e.target;
    setUserData((prev) => ({ ...prev, [name]: value }));
  }

  async function handleSubmit(e) {
    e.preventDefault();

    let { email, name, phone } = userData;

    email = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email.trim())
      ? email.trim()
      : false;
    if (!email) return toast.error("Invalid email address!");

    name =
      /^[a-zA-Z\s]+$/.test(name.trim()) && name.trim().length >= 2
        ? name.trim().toLowerCase()
        : false;
    if (!name) return toast.error("Invalid name!");

    phone = isValidPhoneNumber(phone) ? phone.trim() : false;
    if (!phone) return toast.error("Invalid phone number!");

    const data = {
      emailId: email,
      username: name,
      phno: phone,
      firstName: name,
      network: patientData.network,
      userRole: 2,
      seniorID: id,
    };

    try {
      setIsLoading(true);
      const res = await fetch(`${API_BASE_PATH}/user/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: props.authToken,
        },
        body: JSON.stringify(data),
      });
      res
        .json()
        .then((res) => {
          console.log(res);
          if (res.responseCode === 200) {
            toast.success("Successfully added care giver!");
            setUserData({
              email: "",
              name: "",
              phone: "",
            });
          } else {
            toast.error(res.responseMessage);
          }
        })
        .catch((err) => toast.error(err.message));
    } catch (err) {
      toast.error(err.message);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div>
      <NavbarSuperAdmin />

      <div className="pl">
        <GoToBack>Add Care Giver</GoToBack>
        <div className="Login">
          <form action="POST" onSubmit={handleSubmit}>
            {inputFields.map((item) => (
              <FormGroup
                key={item.label}
                bsSize="large"
                style={{ marginBottom: "15px" }}
              >
                <ControlLabel
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "7px",
                    marginBottom: "5px",
                    fontWeight: "700",
                    maxWidth: "100%",
                  }}
                >
                  {item.icon} {item.label}
                </ControlLabel>

                {item.name === "phone" && (
                  <div id="custom-phone-input">
                    <PhoneInput
                      defaultCountry="US"
                      value={item.value}
                      onChange={handleOnPhoneChange}
                      placeholder={item.placeholder}
                    />
                  </div>
                )}

                {item.name !== "phone" && (
                  <FormControl
                    type={item.type}
                    name={item.name}
                    value={item.value}
                    placeholder={item.placeholder}
                    onChange={handleOnChange}
                    style={{ height: "46px" }}
                  />
                )}
              </FormGroup>
            ))}

            <Button
              type="submit"
              bsSize="large"
              className="text-center"
              style={{
                backgroundColor: isLoading
                  ? "rgb(29 117 189 / 85%)"
                  : "rgb(29, 117, 189)",
                color: "rgb(255, 255, 255)",
                padding: "0.5rem 0",
                borderRadius: "5px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                height: isLoading ? "47.1429px" : "auto",
              }}
              disabled={isLoading}
            >
              {!isLoading && "Submit"}
              <FadeLoader
                height={10}
                loading={isLoading}
                margin={-7}
                speedMultiplier={3}
                width={4}
                color="#fff"
              />
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authToken: state.persist.authToken,
    memberList: state.memberListReducer.memberList,
    userData: state.persist.userData,
  };
};

export default connect(mapStateToProps)(AddCareGiver);
