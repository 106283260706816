import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  FormGroup,
  FormControl,
  ControlLabel,
  InputGroup,
  Row,
  Col,
} from "react-bootstrap";
import { EyeFill } from "react-bootstrap-icons";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import Axios from "axios";
import { toast } from "react-toastify";
import { API_BASE_PATH } from "../../../constants/workerConstants";

const style = {
  marginBottom: "25px",
  background: "#FFFFFF",
  border: "none",
  boxShadow: "0px 2px 1px rgb(0 0 0 /25%)",
  borderRadius: " 11px",
};

export default function AddAdminForm(props) {
  const {
    patientData = "",
    isReadOnly,
    isTitle = true,
    networkID,
    isSuperAdmin,
    isAdd,
    editProfile,
  } = props;

  const history = useHistory();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [landLine, setLandLine] = useState("");
  const [emailId, setEmailId] = useState("");
  const [relationShip, setRelationShip] = useState("");
  const [connexName, setConnexName] = useState("");
  const [address, setAddress] = useState("");
  const [apartment, setApartment] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState("password");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState("password");
  const [quest, setQuest] = useState("");
  const [answer, setAnswer] = useState("");
  const [username, setUserName] = useState("");
  const [hospital, setHospital] = useState("");
  const [hospitalList, setHospitalList] = useState([]);
  const [hospitalID, setHospitalID] = useState([]);
  const [relationShipList, setRelationShipList] = useState([]);
  const [relationShipListID, setRelationShipListID] = useState([]);

  const submitForm = (e) => {
    e.preventDefault();
    if (firstName.trim() == "") return toast.error("Enter First Name");
    if (lastName.trim() == "") return toast.error("Enter Last Name");
    if (emailId.trim() == "") return toast.error("Enter Email Id ");
    if (username.trim() == "") return toast.error("Enter User Name");
    if (
      !String(emailId)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    )
      return toast.error("Enter Correct Email Id ");
    if (relationShip.trim() == "") return toast.error("Select Relationship");
    if (connexName.trim() == "") return toast.error("Enter Social Connex Name");
    if (isNaN(phone)) return toast.error("Enter Valid Phone Number");
    if (!isValidPhoneNumber(phone))
      return toast.error("Phone Number is not valid", {
        toastId: "cell_number_error",
      });
    if (address.trim() == "") return toast.error("Enter Address");
    if (city.trim() == "") return toast.error("Enter City");
    if (state.trim() == "") return toast.error("Enter State");
    if (zipCode.trim() == "") return toast.error("Enter Zip Code");
    if (quest.trim() == "") return toast.error("Select Security Question");
    if (answer.trim() == "") return toast.error("Enter Security Answer");
    if (password.trim() == "") return toast.error("Enter Password");
    if (password.length <= 5)
      return toast.error("Password Should be atleast 6 character");
    if (password !== confirmPassword)
      return toast.error("Confirm Password does not match");
    if (isSuperAdmin && hospital.trim() == "")
      return toast.error("Select Organization Name");

    const data = {
      firstName,
      lastName,
      phno: phone,
      landline: landLine,
      emailId,
      username,
      relationship: relationShipListID?.find((el) => el?.name === relationShip)
        ?._id,
      network: null,
      networkName: connexName,
      address,
      apartmentNumber: apartment,
      city,
      state,
      zipCode,
      securityQuestion: quest,
      answer,
      password,
      userRole: 0,
      deviceType: "WEB",
      hospital: hospitalID?.find((el) => el?.hospitalName === hospital)?.id,
    };

    if (!isSuperAdmin) delete data.hospital;

    Axios.post(`${API_BASE_PATH}/user/register`, data, {
      headers: {
        "content-type": "application/json",
      },
    })
      .then((res) => {
        if (!isSuperAdmin)
          window.localStorage.setItem(
            "netowrkID",
            res.data.result.user.network._id
          );
        toast.success(res.data.responseMessage);
        if (isSuperAdmin) return history.push("/dashboard");
        else if (isAdd) return window.history.back();
        else return history.push("/addpatient");
      })
      .catch((err) => {
        toast.error(err.response.data.responseMessage);
      });
  };

  function getHospitalList() {
    Axios.get(`${API_BASE_PATH}/user/hospitalList`, {
      headers: {
        "content-type": "application/json",
      },
    })
      .then((res) => {
        if (res.data?.result?.list?.length) {
          const arr = res.data.result.list.map((el) => el.hospitalName);
          setHospitalID(
            res.data.result.list.map((el) => ({
              id: el._id,
              hospitalName: el.hospitalName,
            }))
          );
          setHospitalList(arr);
        } else {
          console.log("Unexpected API response", res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function getRelationShip() {
    try {
      const res = await Axios.get(`${API_BASE_PATH}/user/relationships`);
      console.log(res);
      if (res.status === 200) {
        setRelationShipList(res.data.result.relations.map((el) => el.name));
        setRelationShipListID(res.data.result.relations);
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    if (isSuperAdmin) getHospitalList();

    getRelationShip();
  }, []);

  const inputFields = [
    {
      label: "First Name",
      isRequired: true,
      type: "text",
      value: patientData?.firstName || firstName,
      handleOnChange: (e) => setFirstName(e.target.value),
      md: 6,
    },
    {
      label: "Last Name",
      isRequired: true,
      type: "text",
      value: patientData?.lastName || lastName,
      handleOnChange: (e) => setLastName(e.target.value),
      md: 6,
    },
    {
      label: "Email",
      isRequired: true,
      type: "text",
      value: patientData?.emailId || emailId,
      handleOnChange: (e) => setEmailId(e.target.value),
      md: 6,
    },
    {
      label: "User Name",
      isRequired: true,
      type: "text",
      value: patientData?.username || username,
      handleOnChange: (e) => setUserName(e.target.value),
      md: 3,
    },
    {
      label: "Landline",
      isRequired: false,
      type: "text",
      value: patientData?.landline || landLine,
      handleOnChange: (e) => setLandLine(e.target.value),
      md: 3,
    },
    {
      label: "Select Relationship with Senior",
      isRequired: true,
      type: "select",
      value: patientData?.relationship || relationShip,
      handleOnChange: (e) => setRelationShip(e.target.value),
      md: 6,
      option: relationShipList,
    },
    {
      label: "Social Connex Name",
      isRequired: true,
      type: "text",
      value: patientData?.networkName || connexName,
      handleOnChange: (e) => setConnexName(e.target.value),
      md: 6,
    },
    {
      label: "Phone Number (You will get text message for verification)",
      name: "phone",
      isRequired: true,
      type: "number",
      value: patientData?.phno || phone,
      handleOnChange: setPhone,
      md: 6,
    },
    {
      label: "Address",
      isRequired: true,
      type: "text",
      value: patientData?.address || address,
      handleOnChange: (e) => setAddress(e.target.value),
      md: 6,
    },
    {
      label: "Apartment No.",
      isRequired: false,
      type: "text",
      value: patientData?.apartmentNumber || apartment,
      handleOnChange: (e) => setApartment(e.target.value),
      md: 3,
    },
    {
      label: "City",
      isRequired: true,
      type: "text",
      value: patientData?.city || city,
      handleOnChange: (e) => setCity(e.target.value),
      md: 3,
    },
    {
      label: "State",
      isRequired: true,
      type: "text",
      value: patientData?.state || state,
      handleOnChange: (e) => setState(e.target.value),
      md: 3,
    },
    {
      label: "Zip Code",
      isRequired: true,
      type: "text",
      value: patientData?.zipCode || zipCode,
      handleOnChange: (e) => setZipCode(e.target.value),
      md: 3,
    },
    {
      label: "Security Question",
      isRequired: true,
      type: "select",
      value: patientData?.securityQuestion || quest,
      handleOnChange: (e) => setQuest(e.target.value),
      md: 6,
      option: [
        "what city were you born in?",
        "what is your favourite food?",
        "what street did you grow up on?",
        "what is your mother’s maiden name?",
        "where did you meet your spouse?",
        "what Is your favorite book?",
      ],
    },
    {
      label: "Security Answer",
      isRequired: true,
      type: "text",
      value: patientData?.answer || answer,
      handleOnChange: (e) => setAnswer(e.target.value),
      md: 6,
    },
    {
      label: "Password",
      name: "password",
      isRequired: true,
      type: showPassword,
      value: patientData?.password || password,
      handleOnChange: (e) => setPassword(e.target.value),
      handleShowPassword: () =>
        setShowPassword((prev) => (prev === "password" ? "text" : "password")),
      md: 6,
    },
    {
      name: "confirm password",
      label: "Confirm Password",
      isRequired: true,
      type: showConfirmPassword,
      value: patientData?.password || confirmPassword,
      handleOnChange: (e) => setConfirmPassword(e.target.value),
      handleShowPassword: () =>
        setShowConfirmPassword((prev) =>
          prev === "password" ? "text" : "password"
        ),
      md: 6,
    },
    {
      label: "Choose Organization Name",
      isRequired: true,
      type: "select",
      value: patientData?.hospital || hospital,
      handleOnChange: (e) => setHospital(e.target.value),
      md: 12,
      option: hospitalList,
    },
  ];

  if (isReadOnly) inputFields.splice(inputFields.length - 3, 2);

  if (!isSuperAdmin) inputFields.pop();

  return (
    <div style={{ padding: "0px 280px", minWidth: "400px" }}>
      <form style={{ marginTop: "10px" }} onSubmit={submitForm}>
        {isTitle && (
          <>
            <h5
              style={{ fontWeight: 700, color: "#303030", textAlign: "center" }}
            >
              Admin Sign Up Form
            </h5>
            <br />
            <br />
          </>
        )}
        <Row
          style={{
            background: "#ECECEC",
            padding: "1rem",
            marginTop: "-2rem",
            borderRadius: " 5px",
          }}
        >
          {inputFields.map((item) => (
            <Col key={item.label} md={item.md}>
              <FormGroup bsSize="large" style={{ position: "relative" }}>
                <ControlLabel style={{ marginBottom: "10px" }}>
                  <span style={{ color: "#303030" }}>{item.label}</span>
                  {item.isRequired ? (
                    <b style={{ color: "red" }}>*</b>
                  ) : (
                    "(Optional)"
                  )}
                </ControlLabel>

                {item.type == "text" &&
                  item.name !== "password" &&
                  item.name !== "confirm password" && (
                    <FormControl
                      type={item.type}
                      value={item.value}
                      onChange={item.handleOnChange}
                      style={style}
                      disabled={isReadOnly}
                    />
                  )}

                {item.name === "phone" && (
                  <>
                    {isReadOnly && (
                      <div
                        style={{
                          position: "absolute",
                          top: "0",
                          bottom: "0",
                          zIndex: "2",
                          right: "0",
                          left: "0",
                        }}
                      ></div>
                    )}
                    <PhoneInput
                      value={item.value}
                      onChange={item.handleOnChange}
                      defaultCountry="US"
                    />
                  </>
                )}

                {item.type === "select" && (
                  <FormControl
                    componentClass={"select"}
                    value={item.value}
                    onChange={item.handleOnChange}
                    style={style}
                    disabled={isReadOnly}
                  >
                    <option key="0"></option>
                    {item.option.map((text) => (
                      <option key={text} value={text}>
                        {text}
                      </option>
                    ))}
                  </FormControl>
                )}

                {item.name === "password" ||
                item.name === "confirm password" ? (
                  <InputGroup style={{ position: "relative" }}>
                    <FormControl
                      type={item.type}
                      value={item.value}
                      onChange={item.handleOnChange}
                      style={style}
                      disabled={isReadOnly}
                    />
                    <InputGroup.Addon
                      style={{
                        borderLeft: "0",
                        verticalAlign: "middle",
                        textAlign: "center",
                        position: "absolute",
                        zIndex: "9999",
                        bottom: "0.5rem",
                        right: "0.6rem",
                        top: "7px",
                      }}
                      onClick={item.handleShowPassword}
                    >
                      <EyeFill />
                    </InputGroup.Addon>
                  </InputGroup>
                ) : (
                  ""
                )}
              </FormGroup>
            </Col>
          ))}

          {!isReadOnly && (
            <div className="text-center mt-3">
              <Button
                onClick={submitForm}
                className="btn "
                bsSize="medium"
                style={{
                  backgroundColor: "rgb(29, 117, 189)",
                  color: "rgb(255, 255,255)",
                  borderRadius: "12px",
                  padding: "0.3rem 3rem 0.3rem 3rem",
                }}
              >
                Submit
              </Button>
            </div>
          )}
        </Row>
      </form>
    </div>
  );
}
