import React, { useState } from "react";
import {
  Button,
  FormGroup,
  FormControl,
  ControlLabel,
  InputGroup,
  OverlayTrigger,
  Tooltip,
  Row,
  Col,
} from "react-bootstrap";
import { useHistory } from "react-router";
// import { InfoCircle, EyeFill } from 'react-bootstrap-icons';
import NavbarSuperAdmin from "../../components/navbar/NavbarSuperAdmin";
import Axios from "axios";
import { API_BASE_PATH } from "../../constants/workerConstants";

import { getCountries, getStates } from "country-state-picker";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure();

function DoctorForm() {
  const [relations, setRelations] = useState([]);
  const history = useHistory();
  const [wait, setWait] = useState(false);
  const [hospitalName, setHospitalName] = useState("");
  const [state, setState] = useState("");
  const [location, setLocation] = useState("");
  const [city, setCity] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const [country, setCountry] = useState("");

  const submitForm = () => {
    let data;

    if (
      hospitalName !== "" &&
      state !== "" &&
      location !== "" &&
      city !== "" &&
      createdBy !== "" &&
      country !== ""
    ) {
      const countryName = getCountries().filter(
        (data) => data.code === country
      )[0].name;

      data = {
        hospitalName,
        state,
        location,
        city,
        createdBy,
        country: countryName,
      };

      Axios.post(`${API_BASE_PATH}/hospital/createHospital`, data, {
        headers: {
          "content-type": "application/json",
        },
      })
        .then((res) => {
          history.push("/dashboard");
          toast.info("Organization added successfully");
        })
        .catch((err) => {
          console.log("create hospital error");
          toast.error("Something went wrong");
        });
    } else {
      toast.error("Fields marked with * are required.");
    }
  };

  const [stateList, setStateList] = React.useState(null);

  React.useEffect(() => {
    setStateList(getStates(country));
  }, [country]);

  return (
    <>
      <NavbarSuperAdmin />
      <div style={{ padding: "72px 280px" }}>
        <form>
          <h4 style={{ marginTop: 50, fontWeight: 700 }}>Organization Form </h4>
          <br />
          <br />
          <Row>
            <Col md={6}>
              <FormGroup controlId="hospitalName" bsSize="large">
                <ControlLabel style={{ marginBottom: "10px" }}>
                  Organization Name<b style={{ color: "red" }}>*</b>
                </ControlLabel>
                <FormControl
                  autoFocus
                  type="text"
                  value={hospitalName}
                  onChange={(e) => setHospitalName(e.target.value)}
                  required={true}
                  style={{ marginBottom: "12px" }}
                />
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup controlId="hospital" bsSize="large">
                <ControlLabel style={{ marginBottom: "10px" }}>
                  Choose Country<b style={{ color: "red" }}>*</b>
                </ControlLabel>
                <FormControl
                  componentClass="select"
                  value={country}
                  onChange={(e) => {
                    setCountry(e.target.value);
                    // console.log(e.target.value)
                  }}
                  required={true}
                  style={{ marginBottom: "12px" }}
                >
                  <option value="Select Country">Select Country name</option>
                  {getCountries().map((item, index) => {
                    return (
                      <option key={index} value={item.code}>
                        {item.name}
                      </option>
                    );
                  })}
                </FormControl>
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup controlId="hospital" bsSize="large">
                <ControlLabel style={{ marginBottom: "10px" }}>
                  Choose State<b style={{ color: "red" }}>*</b>
                </ControlLabel>
                <FormControl
                  componentClass="select"
                  value={state}
                  onChange={(e) => {
                    setState(e.target.value);
                    // console.log(e.target.value)
                  }}
                  required={true}
                  style={{ marginBottom: "12px" }}
                >
                  <option value="Select State">Select State name</option>
                  {stateList &&
                    stateList.map((item, index) => {
                      return (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      );
                    })}
                </FormControl>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup controlId="location" bsSize="large">
                <ControlLabel style={{ marginBottom: "10px" }}>
                  Location<b style={{ color: "red" }}>*</b>
                </ControlLabel>
                <FormControl
                  type="text"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  required={true}
                  style={{ marginBottom: "12px" }}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup controlId="city" bsSize="large">
                <ControlLabel style={{ marginBottom: "10px" }}>
                  City<b style={{ color: "red" }}>*</b>
                </ControlLabel>
                <FormControl
                  type="text"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  required={true}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup controlId="createdBy" bsSize="large">
                <ControlLabel style={{ marginBottom: "10px" }}>
                  Created By<b style={{ color: "red" }}>*</b>
                </ControlLabel>
                <FormControl
                  type="text"
                  value={createdBy}
                  onChange={(e) => setCreatedBy(e.target.value)}
                  required={true}
                  style={{ marginBottom: "12px" }}
                />
              </FormGroup>
            </Col>
          </Row>
          <div className="text-center mt-4">
            <Button
              bsSize="large"
              onClick={() => submitForm()}
              className="btn"
              style={{
                backgroundColor: "rgb(29, 117, 189)",
                color: "rgb(255, 255,255)",

                marginLeft: "5px",
              }}
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    </>
  );
}

export default DoctorForm;
