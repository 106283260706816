import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Paper, Container } from "@material-ui/core";
import NavbarSuperAdmin from "../../components/navbar/NavbarSuperAdmin";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import Axios from "axios";
import { API_BASE_PATH } from "../../constants/workerConstants";
import { connect } from "react-redux";
import { AppActionTypes } from "../../redux/action-types";
import MyModal from "../../components/ui/app-modal/Modal";
import WarningIcon from "@material-ui/icons/Warning";
import CloseIcon from "@material-ui/icons/Close";
const ECG = {
  NORMAL: "Normal",
  IRREGULAR: "Irregular",
};

function randomNumberBetweenRange(min, max) {
  return Math.floor(Math.random() * (max - min) + 1) + min;
}

function transformMyData(data) {
  return data.map((item, index) => ({
    ...item,
    ecg: index % 3 ? ECG.NORMAL : ECG.IRREGULAR,
    heartRate: index % 4 === 0 ? 106 : randomNumberBetweenRange(70, 100),
    bloodPressure:
      index % 4 === 0
        ? `${randomNumberBetweenRange(155, 181)}/${randomNumberBetweenRange(
            80,
            90
          )}`
        : `${randomNumberBetweenRange(120, 155)}/${randomNumberBetweenRange(
            80,
            90
          )}`,
  }));
}

const PatientDetail = (props) => {
  const classes = useStyles();
  // const [rows, setRows] = useState([]);
  const [rows, setRows] = useState({
    users: [],
    page: 10,
  });
  const [search, setSearch] = useState("");
  const history = useHistory();

  const [openModal, setOpenModal] = React.useState(false);
  const [modalData, setModalData] = React.useState({});
  const [formSave, setFormSave] = useState(null);
  const saveModalData = (data) => {
    setModalData(data);
    setOpenModal(true);
  };
  function handleSearch(e) {
    const { value } = e.target;
    setSearch(value);
  }

  React.useEffect(() => {
    if (props.AppNetworkId) {
      getPatientList();
      // console.log("akshay check", props);
    }

    // getPatientList();
  }, [props.AppNetworkId]);

  const getPatientList = () => {
    Axios.post(
      `${API_BASE_PATH}/user/patientList`,
      {
        networkAdmin: props.AppNetworkId,
      },
      {
        headers: {
          "content-type": "application/json",
        },
      }
    ).then((res) => {
      const data = res.data.result;
      console.log(data);
      const myData = data.filter((a) => a.userRole != 0);
      console.log();
      // console.log("check__check", res.data.result);

      // setRows(res.data.result);
      setRows({ users: myData, page: rows.page });
      // setRows(myData);
    });
  };

  const viewMore = () => {
    setRows((prevState) => {
      return {
        page: prevState.page + 5,
        users: prevState.users,
      };
    });
    //   console.log(rows);
  };
  let p = 5;
  function viewLess() {
    setRows((prevState) => {
      return {
        page: 5,
        users: prevState.users,
      };
    });
  }

  // function getColorClass(type, data) {
  //   switch (type) {
  //     case "heartRate":
  //       return data > 100 ? classes.redColor : "";
  //     case "ecg":
  //       return data === ECG.IRREGULAR ? classes.yellow : "";
  //     case "bloodPressure":
  //       return Number(data.split("/")[0]) > 150 ? classes.redColor : "";
  //     default:
  //       return "";
  //   }
  // }
  function getColorClass(type, data) {
    data = parseInt(data);
    switch (type) {
      case "heartRate":
        return data > 100 ? classes.redColor : data < 60 ? classes.yellow : "";
      case "bloodOxygen":
        return data < 88
          ? classes.redColor
          : data > 88 && data < 95
          ? classes.yellow
          : "";
      default:
        return "";
    }
  }

  return (
    <>
      <NavbarSuperAdmin />

      <div style={{ marginLeft: "0px" }}>
        <div>
          {/* <TextField
            variant="standard"
            label="Search Here"
            name="search"
            value={search}
            onChange={handleSearch}
            style={{
              float: "right",
              marginRight: "99px",
              marginTop: "100px",
              width: "20%",
            }}
          /> */}
          <TextField
            // style={{marginRight:"10px"}}
            variant="standard"
            label="Search Here"
            name="search"
            value={search}
            onChange={handleSearch}
            style={{
              float: "right",
              marginRight: "95px",
              marginTop: "-3px",
              width: "15%",
            }}
          />
        </div>

        <div>
          {/* <Button

            variant="contained"
          
            // color="primary"
            style={{
              float: "right",
              marginTop: "100px",
              height: "50px",
              marginRight: "100px",

              backgroundColor: "rgb(29, 117, 189)",
              color: "rgb(255, 255,255)",
            }}
            onClick={() => setOpenModal(true)}
          >
            + ADD NEW PATIENT

          </Button>
        </div> */}

          {/* </Button>  */}
        </div>

        <React.Fragment>
          <Table
            style={{ marginTop: "120px", marginLeft: "183px", width: "77%" }}
          >
            <TableHead>
              <div class="header" style={{ marginTop: "0px" }}>
                <KeyboardBackspaceIcon
                  onClick={() => window.history.back()}
                  style={{
                    marginTop: "70px",
                    marginLeft: "-7px",
                    height: "40px",
                    width: "40px",
                  }}
                />
                <h4
                  style={{
                    position: "relative",
                    marginTop: "-34px",
                    marginLeft: "40px",
                    width: "100%",
                  }}
                >
                  <b>PATIENT'S DETAIL</b>
                </h4>
              </div>
              <TableRow>
                <TableCell align="center">
                  <b style={{ fontSize: "14px" }}>User Name</b>
                </TableCell>
                <TableCell align="center">
                  <b style={{ fontSize: "14px" }}>First Name</b>
                </TableCell>
                <TableCell align="center">
                  <b style={{ fontSize: "14px" }}>Last Name</b>
                </TableCell>
                <TableCell align="center">
                  <b style={{ fontSize: "14px" }}>Heart Rate</b>
                </TableCell>
                <TableCell align="center">
                  <b style={{ fontSize: "14px" }}>Step Count</b>
                </TableCell>
                <TableCell align="center">
                  <b style={{ fontSize: "14px" }}>Blood Oxygen</b>
                </TableCell>
                <TableCell align="center">
                  <b style={{ fontSize: "14px" }}>Vital Status</b>
                </TableCell>

                {/* <TableCell align="center">
                  <b style={{ fontSize: "14px" }}>Blood Pressure</b>
                </TableCell> */}
                {/* <TableCell align="center">
                  <b style={{ fontSize: "14px" }}>Ecg</b>
                </TableCell> */}
              </TableRow>
            </TableHead>
            {rows.users.length == 0 ? (
              <div
                className="text-center mt-5 "
                style={{
                  backgroundColor: "rgb(29, 117, 189)",
                  color: "rgb(255, 255,255)",
                  padding: "0.5rem",
                  marginRight: "-45rem",
                }}
              >
                No Data Available
              </div>
            ) : (
              <TableBody>
                {rows.users
                  .filter((i) =>
                    search.length > 0
                      ? i.firstName
                          .toLowerCase()
                          .startsWith(search.toLowerCase())
                      : i
                  )
                  .slice(0, rows.page)
                  .map((row) => (
                    <TableRow key={row.id}>
                      <TableCell align="center" style={{ fontSize: "12px" }}>
                        <b style={{ color: "black" }}>
                          {row.username == null ? "-" : row.username}
                        </b>
                      </TableCell>
                      <TableCell align="center" style={{ fontSize: "12px" }}>
                        <b>{row.firstName == null ? "-" : row.firstName}</b>
                      </TableCell>
                      <TableCell align="center" style={{ fontSize: "12px" }}>
                        <b>{row.lastName == null ? "-" : row.lastName}</b>
                      </TableCell>
                      {/* <TableCell
                        align="center"
                        style={{ fontSize: "12px" }}
                        className={getColorClass("heartRate", row.heartRate)}
                      >
                        <b>{row.heartRate == null ? "-" : row.heartRate}</b>
                      </TableCell> */}
                      <TableCell
                        align="center"
                        style={{
                          fontSize: "12px",
                        }}
                        className={getColorClass("heartRate", row.heartRate)}
                      >
                        <b>
                          {row.heartRate == null ? (
                            <div>{"-"}</div>
                          ) : (
                            <div
                              style={{
                                cursor: "pointer",
                                textDecoration: "underline",
                              }}
                            >
                              <div
                                onClick={() => {
                                  props.setAppNetworkHeartID(row._id);
                                  history.push(
                                    "/appnetwork/appnetworkheartrate"
                                  );
                                }}
                              >
                                {row.heartRate}
                              </div>
                            </div>
                          )}
                        </b>
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        <b>
                          {row.stepCount == null ? (
                            <div>{"-"}</div>
                          ) : (
                            <div
                              style={{
                                cursor: "pointer",
                                textDecoration: "underline",
                              }}
                            >
                              <div
                                onClick={() => {
                                  props.setAppNetworkHeartID(row._id);
                                  history.push(
                                    "/appnetwork/appnetworkstepcount"
                                  );
                                }}
                              >
                                {row.stepCount}
                              </div>
                            </div>
                          )}
                        </b>
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          fontSize: "12px",
                        }}
                        className={getColorClass(
                          "bloodOxygen",
                          row.bloodOxygen
                        )}
                      >
                        <b>
                          {row.bloodOxygen == null ? (
                            <div>{"-"}</div>
                          ) : (
                            <div
                              style={{
                                cursor: "pointer",
                                textDecoration: "underline",
                              }}
                            >
                              <div
                                onClick={() => {
                                  props.setAppNetworkHeartID(row._id);
                                  history.push(
                                    "/appnetwork/appnetworkbloodoxygen"
                                  );
                                }}
                              >
                                {row.bloodOxygen}
                              </div>
                            </div>
                          )}
                        </b>
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        {
                          // (row?.whenSendVitalPush!=null)?<WarningIcon style={{color:'red',cursor:'pointer'}} onClick={()=>saveModalData(row)}/>:<span style={{color:'green',fontWeight:'bold'}}>Active</span>
                          row?.whenSendVitalPush != null &&
                          row?.lastVitals != null ? (
                            <WarningIcon
                              style={{ color: "red", cursor: "pointer" }}
                              onClick={() => saveModalData(row)}
                            />
                          ) : !row.hasOwnProperty("whenSendVitalPush") &&
                            !row.hasOwnProperty("lastVitals") ? (
                            "-"
                          ) : (
                            <span
                              style={{ color: "green", fontWeight: "bold" }}
                            >
                              Active
                            </span>
                          )
                        }
                      </TableCell>

                      {/* <TableCell align="center" style={{ fontSize: "12px" }}>
                        <b>{row.bloodOxygen == null ? "-" : row.bloodOxygen}</b>
                      </TableCell> */}
                      {/* <TableCell
                      align="center"
                      style={{ fontSize: "12px" }}
                      //   className={getColorClass(
                      //     "bloodPressure",
                      //     row.bloodPressure
                      //   )}
                    >
                      <b>
                        {row.bloodPressure == null ? "-" : row.bloodPressure}
                      </b>
                    </TableCell> */}
                      {/* <TableCell
                        align="center"
                        style={{ fontSize: "12px" }}
                        //   className={getColorClass("ecg", row.ecg)}
                      >
                        <b>{row.ecg}</b>
                      </TableCell> */}
                    </TableRow>
                  ))}
                {
                  <div
                    className="text-center mt-5"
                    style={{ marginRight: "-45rem" }}
                  >
                    {rows.page >= rows.users.length && rows.users.length > 5 ? (
                      <div>
                        <button className="btn btn-warning " onClick={viewLess}>
                          View Less
                        </button>
                      </div>
                    ) : (
                      <div>
                        {rows.users.length > 5 ? (
                          <button
                            className="btn"
                            style={{
                              backgroundColor: "rgb(29, 117, 189)",
                              color: "rgb(255, 255,255)",
                            }}
                            onClick={viewMore}
                          >
                            View More
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                  </div>
                }
              </TableBody>
            )}
          </Table>
        </React.Fragment>
        <MyModal open={openModal} showHalf={true}>
          <CloseIcon
            style={{ float: "right", cursor: "pointer" }}
            onClick={() => setOpenModal(false)}
          />
          <br></br>
          <h4 style={{ textAlign: "center" }}>Last Vital Recieved </h4>
          <h5 style={{ textAlign: "center" }}>
            {new Date(modalData.lastVitals).toLocaleString()}
          </h5>
          <br></br>
        </MyModal>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.persist.userData,
    AppNetworkId: state.persist.AppNetworkId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAppNetworkHeartID: (id) =>
      dispatch({
        type: AppActionTypes.SET_APP_NETWORK_HEART_ID,
        payload: id,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientDetail);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  theader: {
    "& th": {
      fontSize: "1.4rem",
      fontWeight: "bold",
    },
  },
  row: {
    "& td": {
      fontSize: "1.0rem",
      fontWeight: 600,
    },
    "& a": {
      color: theme.palette.common.black,
    },
  },
  redColor: {
    background: "red",
    color: theme.palette.common.white,
    "& a": {
      color: theme.palette.common.white,
    },
  },
  yellow: {
    background: "yellow",
    color: theme.palette.common.black,
    "& a": {
      color: theme.palette.common.black,
    },
  },
}));
