import React, { useState } from "react";
import styles from "./MediaNav.module.css";

const MediaNav = ({ onChange, activeTab, btns }) => {
  return (
    <div className={styles.mediaNav}>
      {btns.map((item) => (
        <button
          key={item}
          className={item === activeTab ? styles.activeBtn : ""}
          onClick={() => {
            onChange(item);
          }}
        >
          {item}
        </button>
      ))}
    </div>
  );
};

export default MediaNav;
