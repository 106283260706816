import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  Button,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

function ContentDialog(props) {
  const { confirmDialog, setConfirmDialog } = props;
  return (
    <>
      <Dialog
        open={confirmDialog.isOpen}
        PaperProps={{
          style: {
            minWidth: "250px",
            overflow: "hidden",
          },
        }}
      >
        <DialogContent style={{ fontWeight: "bold", alignItems: "left" }}>
          <Typography>{confirmDialog.date}</Typography>
        </DialogContent>

        <DialogActions style={{ marginTop: "-55px", textAlign: "end" }}>
          <IconButton onClick={() => setConfirmDialog({ isOpen: false })}>
            <CloseIcon />
          </IconButton>
        </DialogActions>
        {confirmDialog.time && (
          <DialogContent style={{ marginRight: "-9px" }}>
            <Typography>{confirmDialog.time}</Typography>
          </DialogContent>
        )}

        {confirmDialog.reminder && (
          <DialogContent style={{ marginRight: "-9px" }}>
            <Typography>{confirmDialog.reminder}</Typography>
          </DialogContent>
        )}

        {confirmDialog.location && (
          <DialogContent style={{ marginRight: "-9px" }}>
            <Typography>{confirmDialog.location}</Typography>
          </DialogContent>
        )}

        <DialogContent style={{ marginRight: "-9px" }}>
          <Typography>{confirmDialog.title}</Typography>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ContentDialog;
