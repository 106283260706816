import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import NavbarSuperAdmin from "../../../components/navbar/NavbarSuperAdmin";
import InsertEmoticonOutlinedIcon from "@material-ui/icons/InsertEmoticonOutlined";
import AttachFileOutlinedIcon from "@material-ui/icons/AttachFileOutlined";
import MicIcon from "@material-ui/icons/Mic";
import EmojiPicker from "emoji-picker-react";
import SendIcon from "@material-ui/icons/Send";
import AttachedMenu from "../../../components/ui/chat attached data/AttachedMenu";
import Webcam from "react-webcam";
import LinkedCameraIcon from "@material-ui/icons/LinkedCamera";
import {
  ArrowDown,
  ArrowLeft,
  Download,
  PlayBtnFill,
} from "react-bootstrap-icons";
import { IoAttachOutline } from "react-icons/io5";
import DisplayMedia from "../../../components/partials/DisplayMedia/DisplayMedia";
import LoadingSpinner from "../../../components/partials/LoadingSpinner";
import { uid } from "uid";
import { SOCKET_BASE_PATH } from "../../../constants/workerConstants";

const videoConstraints = {
  facingMode: "user",
};

// const socketUrl = "wss://staging.socket.seniorconnex.com";
// const socketUrl = "ws://localhost:4000";
const socketUrl = SOCKET_BASE_PATH;

const events = [
  "reaction",
  "message",
  "document",
  "image",
  "video",
  "audio",
  "loadOldData",
];

let isInitial = true;
const reconnectInterval = 5000;

const Chat = (props) => {
  const history = useHistory();

  /** @type {React.MutableRefObject<WebSocket | null>} */
  const ws = useRef(null);
  const { id } = useParams();
  const webcamRef = useRef(null);

  const [chatData, setChatData] = useState([]);
  const [sendMsg, setSendMsg] = useState("");
  const [isOpen, setIsOpen] = useState("");
  const [capturedImgSrc, setCapturedImgSrc] = useState("");
  const [active, setActive] = useState("");
  const [groupedMessages, setGroupedMessages] = useState({});
  const [networkName, setNetworkName] = useState("");
  const [loadingPage, setLoadingPage] = useState(true);
  const [userScrolledUp, setUserScrolledUp] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const chatBoxRef = useRef(null);
  const [showMedia, setShowMedia] = useState({ type: "", url: "" });
  const [mediaLoading, setMediaLoading] = useState([]);
  const [loadmoreLoading, setLoadmoreLoading] = useState(false);

  console.log("chatData_____", chatData);

  const connectWebsocket = () => {
    ws.current = new WebSocket(socketUrl, ["Authorization", props.authToken]);

    ws.current.onopen = (e) => {
      console.log("WebSocket connection established", e);
      setTimeout(() => {
        setLoadingPage(false);
      }, 500);
    };

    ws.current.onmessage = (event) => {
      const message = JSON.parse(event.data); // this is = {data: {docs: [{}]}}
      console.log("Message received:", message);

      if (!networkName) {
        setNetworkName(message.network.networkName);
      }

      // if (loadmoreLoading) {
      //   console.log("loadmore data=", message);
      //   setLoadmoreLoading(false);
      // }

      setChatData((prevChatData) => [...prevChatData, message]);
    };

    ws.current.addEventListener("message", (event) => {
      const messages = JSON.parse(event.data);
      console.log("addEventListener message==", messages);

      const page = messages.data.page;
      const totalPages = messages.data.pages;
      setCurrentPage(page);
      setTotalPages(totalPages);

      const data = messages.data.docs
        .map((item) => {
          const userImg =
            "https://cdn1.iconfinder.com/data/icons/user-pictures/101/malecostume-512.png";

          const fileNameMatch = item.content.match(/-fileName-(.+)$/);
          const fileName = fileNameMatch
            ? decodeURIComponent(fileNameMatch[1])
            : null;

          return {
            msg: item.content,
            time: item.createdAt,
            type:
              item.user.emailId === props.userData.emailId
                ? "my_message"
                : "friend_message",
            profileImg: userImg,
            userId: item.user._id,
            event: item.event,
            fileName,
            mediaId: item.mediaId,
          };
        })
        .sort((a, b) => new Date(a.time) - new Date(b.time));

      console.log("message.events=", messages.event);
      if (messages.event === "pagination") {
        console.log("paginate==");
        setLoadmoreLoading(false);
        setChatData((prevChatData) => [...data, ...prevChatData]);

        // Save the current scroll position
        const scrollPosition =
          chatBoxRef.current.scrollHeight - chatBoxRef.current.scrollTop;

        // Restore the scroll position
        setTimeout(() => {
          chatBoxRef.current.scrollTop =
            chatBoxRef.current.scrollHeight - scrollPosition;
        }, 0);

        return;
      }

      if (isInitial === false) {
        const lastMessage = data[data.length - 1];

        if (lastMessage.userId !== props.userData._id) {
          setChatData((prevChatData) => {
            if (
              !prevChatData.some((message) => message.time === lastMessage.time)
            ) {
              return [...prevChatData, lastMessage];
            }
            return prevChatData;
          });
        }

        if (
          lastMessage.userId === props.userData._id &&
          lastMessage.event !== "message" &&
          lastMessage.mediaId
        ) {
          setChatData((prevChatData) =>
            prevChatData.map((message) =>
              message.mediaId === lastMessage.mediaId
                ? { ...message, isLoading: false }
                : message
            )
          );
        }

        return;
      }
      isInitial = false;

      setChatData([...data]);
    });
  };

  // WebSocket connection and listners
  useEffect(() => {
    connectWebsocket();

    const interval = setInterval(() => {
      if (ws.current && ws.current.readyState !== WebSocket.OPEN) {
        connectWebsocket();
      }
    }, reconnectInterval);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const formatDate = (date) => {
      const today = new Date();
      const messageDate = new Date(date);
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);

      if (
        messageDate.getDate() === today.getDate() &&
        messageDate.getMonth() === today.getMonth() &&
        messageDate.getFullYear() === today.getFullYear()
      ) {
        return "Today";
      } else if (
        messageDate.getDate() === yesterday.getDate() &&
        messageDate.getMonth() === yesterday.getMonth() &&
        messageDate.getFullYear() === yesterday.getFullYear()
      ) {
        return "Yesterday";
      } else {
        return messageDate.toLocaleDateString();
      }
    };

    const groupedMessages = chatData.reduce((acc, message) => {
      const date = formatDate(message.time);

      if (date !== "Invalid Date" && events.includes(message.event)) {
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(message);
      }
      return acc;
    }, {});

    if (Object.keys(groupedMessages).length > 0) {
      setGroupedMessages(groupedMessages);
    }
  }, [chatData]);

  useEffect(() => {
    const handleScroll = () => {
      if (chatBoxRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = chatBoxRef.current;
        if (scrollTop + clientHeight < scrollHeight - 100) {
          setUserScrolledUp(true);
        } else {
          setUserScrolledUp(false);
        }
      }
    };

    if (chatBoxRef.current) {
      chatBoxRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (chatBoxRef.current) {
        chatBoxRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (!userScrolledUp) {
      const timeout = setTimeout(() => {
        if (chatBoxRef.current) {
          chatBoxRef.current.scrollTo({
            top: chatBoxRef.current.scrollHeight,
            behavior: "smooth",
          });
        }
      }, 600);

      return () => clearTimeout(timeout);
    }
  }, [groupedMessages, userScrolledUp]);

  // useEffect(() => {
  //   const checkMediaLoading = () => {
  //     setTimeout(() => {
  //       setChatData((prevChatData) => {
  //         return prevChatData.map((item) => {
  //           if (item.isLoading && item.event !== "message") {
  //             return { ...item, isLoading: false };
  //           }
  //           return item;
  //         });
  //       });
  //     }, 6000);
  //   };

  //   if (mediaLoading.length > 0) {
  //     checkMediaLoading();
  //   }
  // }, [mediaLoading]);

  const sendWsMessage = (content, eventType, fileName) => {
    const message = {
      event: eventType ? eventType : "message",
      content: content,
    };

    if (message.event !== "message") {
      const mediaId = uid(32);

      message.fileName = fileName;
      message.mediaId = mediaId;
      message.isLoading = true;
    }

    const dummyImg =
      "https://cdn1.iconfinder.com/data/icons/user-pictures/101/malecostume-512.png";

    const newMessage = {
      ...message,
      msg: content,
      time: new Date().toISOString(),
      type: "my_message",
      profileImg: dummyImg,
      userId: props.userData._id,
      event: message.event,
    };

    console.log("newMessage", newMessage);
    setChatData((prevChatData) => [...prevChatData, newMessage]);
    setSendMsg("");

    if (message.event !== "message") {
      const fileUrl = content?.split("base64,")[1];
      message.content = fileUrl;

      setMediaLoading((prevMediaLoading) => [
        ...prevMediaLoading,
        message.mediaId,
      ]);
    }

    console.log("sendingMessage==", message);
    if (ws.current && ws.current.readyState === WebSocket.OPEN) {
      ws.current.send(JSON.stringify(message));
    } else {
      ws.current = new WebSocket(socketUrl, ["Authorization", props.authToken]);
      ws.current.onopen = () => {
        ws.current.send(JSON.stringify(message));
      };
    }
  };

  function handleEmoji(data, e) {
    console.log("onEmoji", e, data, data.emoji);
    setSendMsg((prevSendMsg) => `${prevSendMsg} ${data.emoji}`);
    e.stopPropagation();
  }

  function handleSmileIcon(e) {
    const payload = isOpen === "emojiModel" ? "" : "emojiModel";
    setIsOpen(payload);
    e.stopPropagation();
  }

  function handleAttachedIcon(e) {
    const payload = isOpen === "attachedModel" ? "" : "attachedModel";
    setIsOpen(payload);
    e.stopPropagation();
  }

  function handleTypeMsg(e) {
    setSendMsg(e.target.value);
  }

  function handleChatContainer(e) {
    setIsOpen("");
  }

  function handleSendMsg() {
    sendWsMessage(sendMsg);
  }

  function handleCapture() {
    const imageSrc = webcamRef.current.getScreenshot();
    setCapturedImgSrc(imageSrc);
    setActive("capturedImage");
    console.log(imageSrc);
  }

  function handleRetake() {
    setCapturedImgSrc("");
  }
  function handleCameraIcon() {
    setActive("camera");
    setIsOpen("");
  }

  const handleSendMedia = (type, base64String, fileName) => {
    setIsOpen("");
    const fileType = type === "application" ? "document" : type;
    sendWsMessage(base64String, fileType, fileName);
  };

  const handleDownloadDocument = (link) => {
    window.open(link, "_blank");
    // const a = document.createElement("a");
    // a.target = "_blank";
    // a.href = link;
    // a.download = link; // Extracts the file name from the link
    // document.body.appendChild(a);
    // a.click();
    // document.body.removeChild(a);
  };

  const handleLoadMoreChat = () => {
    console.log("load more chat");
    if (+currentPage === +totalPages || currentPage > totalPages) return;
    setLoadmoreLoading(true);
    setCurrentPage(currentPage + 1);
    ws.current.send(
      JSON.stringify({ event: "loadOldData", pageNumber: currentPage + 1 })
    );
  };

  const handleScrollToBottom = () => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTo({
        top: chatBoxRef.current.scrollHeight + 100,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <div>
        <NavbarSuperAdmin />
        <div className="pl" style={{ marginTop: "70px" }}>
          <div id="chat_container">
            <div className="chat_main cover" onClick={handleChatContainer}>
              <div className="chat_header">
                <button className="backBtn" onClick={() => history.goBack()}>
                  <ArrowLeft />
                </button>{" "}
                {networkName}
              </div>

              <div
                ref={chatBoxRef}
                className="chat_box"
                style={
                  active === "camera" || active === "capturedImage"
                    ? { background: "#f0f0f0" }
                    : {}
                }
              >
                {loadingPage && (
                  <div className="chat-loading-spinner-container">
                    <LoadingSpinner />
                  </div>
                )}
                {!loadingPage && (
                  <>
                    {currentPage < totalPages && (
                      <button
                        disabled={loadmoreLoading}
                        className="chat-load-more-btn"
                        onClick={handleLoadMoreChat}
                      >
                        {loadmoreLoading ? (
                          <LoadingSpinner color="#fff" />
                        ) : (
                          "Click here to load more"
                        )}
                      </button>
                    )}
                    {active === "" &&
                      Object.keys(groupedMessages).map((date, index) => (
                        <div key={index}>
                          <div className="date-header">{date}</div>
                          {groupedMessages[date].map((item, i) => (
                            <div className={`message ${item.type}`} key={i}>
                              <div
                                id={
                                  item.event !== "message"
                                    ? "message-media-content"
                                    : ""
                                }
                                className={`message-content`}
                              >
                                {item.isLoading && (
                                  <>
                                    <span className="media-loading">
                                      <LoadingSpinner />
                                    </span>
                                    <div className="media-loading-overlay" />
                                  </>
                                )}

                                {item.event === "message" && item.msg}

                                {item.event === "document" && (
                                  <div className="message-content-document-container">
                                    <p>
                                      <IoAttachOutline />{" "}
                                      {item.fileName ?? "Document"}
                                    </p>
                                    <button
                                      onClick={() =>
                                        handleDownloadDocument(item.msg)
                                      }
                                      className="message-content-document-download-btn"
                                    >
                                      <Download />
                                    </button>
                                  </div>
                                )}

                                {item.event === "audio" && (
                                  <div className="message-audio-container">
                                    <p className="message-audio-name">
                                      Audio Name
                                    </p>
                                    <audio
                                      controls
                                      src={item.msg}
                                      className="message-audio"
                                    />
                                  </div>
                                )}

                                {item.event === "video" && (
                                  <>
                                    <div className="message-video-action-btns">
                                      <button
                                        onClick={() =>
                                          setShowMedia({
                                            type: item.event,
                                            url: item.msg,
                                          })
                                        }
                                        className="message-video-play-btn"
                                      >
                                        <PlayBtnFill />
                                      </button>

                                      {/* <button
                                        onClick={() =>
                                          handleDownloadDocument(item.msg)
                                        }
                                        className="message-content-download-btn"
                                      >
                                        <Download />
                                      </button> */}
                                    </div>

                                    <video
                                      src={item.msg}
                                      className="message-video"
                                    ></video>
                                  </>
                                )}

                                {item.event === "image" && (
                                  <>
                                    <img
                                      key={item.msg.slice(0, 30)}
                                      className="message-img"
                                      src={item.msg}
                                      alt="media"
                                      onError={(e) => {
                                        e.target.classList.add("hide-media");
                                      }}
                                      onClick={() =>
                                        setShowMedia({
                                          type: item.event,
                                          url: item.msg,
                                        })
                                      }
                                    />

                                    {/* <button
                                      onClick={() =>
                                        handleDownloadDocument(item.msg)
                                      }
                                      className="message-img-download-btn"
                                    >
                                      <Download />
                                    </button> */}
                                  </>
                                )}
                                <span className="message-time">
                                  {new Date(item.time).toLocaleTimeString([], {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    hour12: true,
                                  })}
                                </span>
                              </div>
                            </div>
                          ))}
                        </div>
                      ))}

                    {(active === "camera" || active === "capturedImage") && (
                      <div
                        style={{
                          position: "relative",
                          height: "100%",
                          width: "100%",
                        }}
                      >
                        {active === "camera" && (
                          <Webcam
                            ref={webcamRef}
                            width="100%"
                            height="100%"
                            screenshotFormat="image/jpeg"
                            videoConstraints={videoConstraints}
                            mirrored={true}
                          />
                        )}
                        {active === "capturedImage" && capturedImgSrc && (
                          <img
                            src={capturedImgSrc}
                            alt="capture photo"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "contain",
                            }}
                          />
                        )}
                        <div
                          className="sendBtn"
                          style={{
                            color: "#ff2e74",
                            position: "absolute",
                            left: "50%",
                            bottom: "0px",
                            transform: "translate(-50%, 50%)",
                            width: "50px",
                            height: "50px",
                            cursor: "pointer",
                          }}
                          onClick={handleCapture}
                        >
                          <LinkedCameraIcon
                            fontSize="large"
                            style={{ color: "#fff" }}
                          />
                        </div>
                        <div
                          className="sendBtn"
                          style={{
                            color: "#ff2e74",
                            position: "absolute",
                            left: "50%",
                            bottom: "0px",
                            transform: "translate(-50%, 50%)",
                            width: "50px",
                            height: "50px",
                            cursor: "pointer",
                          }}
                          onClick={handleCapture}
                        >
                          <LinkedCameraIcon
                            fontSize="large"
                            style={{ color: "#fff" }}
                          />
                        </div>
                      </div>
                    )}
                  </>
                )}

                {userScrolledUp && (
                  <button
                    className="chat-scroll-to-bottom-btn"
                    onClick={handleScrollToBottom}
                  >
                    <ArrowDown />
                  </button>
                )}
              </div>

              {!loadingPage && (
                <div className="chat_input">
                  <div
                    style={{ position: "absolute", bottom: "60px" }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <EmojiPicker
                      open={isOpen === "emojiModel"}
                      onEmojiClick={handleEmoji}
                      height={390}
                    />
                  </div>
                  <InsertEmoticonOutlinedIcon onClick={handleSmileIcon} />

                  <div
                    style={{ position: "absolute", bottom: "60px" }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {isOpen === "attachedModel" && (
                      <AttachedMenu
                        onCamera={handleCameraIcon}
                        onSelectMedia={handleSendMedia}
                      />
                    )}
                  </div>

                  {active !== "capturedImage" && (
                    <AttachFileOutlinedIcon onClick={handleAttachedIcon} />
                  )}

                  <textarea
                    rows={1}
                    placeholder="Type a message"
                    className="type_input"
                    value={sendMsg}
                    onChange={handleTypeMsg}
                  />
                  {!sendMsg.trim() && active !== "capturedImage" && <MicIcon />}
                  {(sendMsg.trim() || active === "capturedImage") && (
                    <div className="sendBtn" onClick={handleSendMsg}>
                      <SendIcon style={{ color: "#fff" }} />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {showMedia.url && (
        <DisplayMedia
          type={showMedia.type}
          url={showMedia.url}
          onClose={() => setShowMedia({ type: "", url: "" })}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    authToken: state.persist.authToken,
    memberList: state.memberListReducer.memberList,
    userData: state.persist.userData,
  };
};

export default connect(mapStateToProps)(Chat);
