import React, { useEffect, useRef, useState } from "react";
import styles from "./MediaList.module.css";
import { IoAttachOutline, IoDownloadOutline } from "react-icons/io5";
import DisplayMedia from "../../../partials/DisplayMedia/DisplayMedia";
import MediaNav from "../MediaNav/MediaNav";
import axios from "axios";
import { API_BASE_PATH } from "../../../../constants/workerConstants";
import { connect } from "react-redux";
import { PlayBtnFill } from "react-bootstrap-icons";
import ReactPaginate from "react-paginate";

const skeleton = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const tabs = ["All Media", "Images", "Videos", "Audio", "Documents"];

const MediaList = (props) => {
  const [showMedia, setShowMedia] = useState({ type: "", url: "" });
  const [active, setActive] = useState(tabs[0]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState([]);
  const [mediaData, setMediaData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currPageData, setCurrPageData] = useState([]);
  const [totalMedia, setTotalMedia] = useState(0);

  const targetRef = useRef(null);

  // console.log("totalMedia=", totalMedia);
  // console.log("currPageData=", currPageData);

  useEffect(() => {
    if (!props.authToken || mediaData.length > 0) return;

    setIsLoading(true);
    axios
      .get(`${API_BASE_PATH}/gallery/getUserMedia?value=all`, {
        headers: { Authorization: props.authToken },
      })
      .then((res) => {
        setMediaData(res.data.content);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
      });
  }, [props.authToken]);

  useEffect(() => {
    const total = Math.ceil(currPageData.length / 20);
    setTotalPages(total);
  }, [currPageData]);

  // currPageData
  useEffect(() => {
    if (mediaData.length === 0) return;
    setIsLoading(true);

    let updatedData = [];

    if (active === "All Media") {
      updatedData = mediaData;
    } else if (active === "Images") {
      updatedData = mediaData.filter((item) => getItemType(item) === "image");
    } else if (active === "Videos") {
      updatedData = mediaData.filter((item) => getItemType(item) === "video");
    } else if (active === "Audio") {
      updatedData = mediaData.filter((item) => getItemType(item) === "audio");
    } else if (active === "Documents") {
      updatedData = mediaData.filter(
        (item) => getItemType(item) === "document"
      );
    }

    setTotalMedia(updatedData.length);
    setCurrPageData(updatedData);
    setIsLoading(false);
    setPage(1);
  }, [mediaData, active]);

  const handleShowMedia = (type, url) => {
    setShowMedia({ type, url });
  };

  // const currPageData = mediaData.slice(page - 1, page * 20);

  const getItemType = (item) => {
    const arr = item.split(".");
    const type = arr[arr.length - 1];

    if (type === "mp4" || type === "mov" || type === "wmv" || type === "avi")
      return "video";

    if (type === "mp3" || type === "wav" || type === "ogg") return "audio";
    if (type === "jpg" || type === "png" || type === "jpeg" || type === "webp")
      return "image";

    return "document";
  };

  const getFileName = (item) => {
    if (!item) return;
    const fileNameMatch = item?.match(/-fileName-(.+)$/);

    if (!fileNameMatch) return null;

    const nameParts = fileNameMatch[1].split(".");
    const fileName = nameParts.slice(0, -1).join("."); // Merge all but the last part

    return decodeURIComponent(fileName);
  };

  const handleDownloadDocument = (link) => {
    window.open(link, "_blank");
  };

  return (
    <>
      <MediaNav
        onChange={(tab) => setActive(tab)}
        activeTab={active}
        btns={tabs}
      />

      <div className={styles.mediaList}>
        {!isLoading &&
          mediaData &&
          currPageData.slice((page - 1) * 20, page * 20).map((item, i) => {
            const mediaType = getItemType(item);
            const isOpenable = mediaType === "image" || mediaType === "video";

            return (
              <div id={mediaType} key={i} className={`${styles.card}`}>
                <button
                  onClick={() => {
                    handleDownloadDocument(item);
                  }}
                  className={styles.downloadBtn}
                >
                  <IoDownloadOutline />
                </button>

                <div
                  style={
                    isOpenable ? { cursor: "pointer" } : { cursor: "auto" }
                  }
                  className={styles.imageContainer}
                  onClick={() => {
                    if (isOpenable) {
                      handleShowMedia(mediaType, item);
                    }
                  }}
                >
                  {mediaType === "image" && <img src={item} alt="image" />}
                  {mediaType === "video" && (
                    <>
                      <video src={item} alt="video" />
                      <button className={styles.videoPlayBtn}>
                        <PlayBtnFill />
                      </button>
                    </>
                  )}
                  {mediaType === "audio" && (
                    <>
                      <p>{getFileName(item)}</p>
                      <audio controls src={item} alt="audio" />
                    </>
                  )}
                  {mediaType === "document" && (
                    <div className={styles.documentContainer}>
                      <IoAttachOutline />{" "}
                      <p>{getFileName(item) || "Document"}</p>
                    </div>
                  )}
                </div>
              </div>
            );
          })}

        {isLoading &&
          skeleton.map((item) => (
            <div
              className={`${styles.card} ${styles.cardSkeleton} placeholder`}
            ></div>
          ))}
      </div>

      {!isLoading && currPageData.length < totalMedia && (
        <button
          className={styles.loadMoreBtn}
          onClick={() => {
            setPage(page + 1);
          }}
        >
          Load more
        </button>
      )}

      {totalPages > 1 && (
        <div className={styles.paginationContainer}>
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={totalPages}
            marginPagesDisplayed={4}
            pageClassName={"navi-li"}
            pageRangeDisplayed={2}
            onPageChange={(page) => {
              setPage(page.selected + 1);
              console.log(page.selected + 1);
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
            containerClassName={"pagination"}
            activeClassName={"active"}
          />
        </div>
      )}

      {showMedia.url && (
        <DisplayMedia
          type={showMedia.type}
          url={showMedia.url}
          onClose={() => setShowMedia({ type: "", url: "" })}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    authToken: state.persist.authToken,
  };
};

export default connect(mapStateToProps)(MediaList);
