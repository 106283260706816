import React from "react";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";

export const GoToBack = ({ children }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "15px",
        marginBottom: "50px",
      }}
      className="responsive"
    >
      <KeyboardBackspaceIcon
        onClick={() => window.history.back()}
        style={{
          height: "40px",
          width: "40px",
        }}
        className="GoToBackIcon"
      />
      <h4
        style={{
          margin: 0,
        }}
      >
        <b>{children}</b>
      </h4>
    </div>
  );
};
