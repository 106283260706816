import React from 'react'
import dateFormat from "dateformat";

import {ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Legend, Tooltip} from "recharts";

export const Oxygen = (prop) => {
    let datearr = []; 
    let data = [];
    var lowest = Number.POSITIVE_INFINITY;
    var highest = Number.NEGATIVE_INFINITY;
    var tmp;
    for (var i= prop.data.length-1; i>=0; i--) {
        tmp = 
        parseInt(prop.data[i].bloodOxygen.replace('%','') , 10 ) ;
        if (tmp < lowest) lowest = tmp;
        if (tmp > highest) highest = tmp;
    }
     prop.data.forEach(item => {
        let  date =  new Date(item.createdAt).toLocaleString("en-IN", {
            day: "2-digit",
            month: "2-digit",
          })
          
         // year: "numeric",
         const time = dateFormat(new Date(item.createdAt), "mm/dd HH:MM").toLocaleString("en-IN");
         // const time =  new Date(item.createdAt).toLocaleString("en-IN", { 
         //   day: "2-digit",
         //   month: "2-digit",    
         //     hour: "2-digit",
         //     minute: "2-digit",
         //     hour12: false 
         //     // second: "2-digit",
         //   })
        let d = dateFormat(new Date(item.createdAt), "mm/dd/yyyy").toLocaleString()
         
       datearr.push(d);   
       data.push({"Blood Oxygen (%)":item.bloodOxygen.replace('%',''),'time':time});  
    });
    datearr = datearr.filter((v, i, a) => a.indexOf(v) === i)
    datearr = datearr.reverse();
    datearr = datearr.join('-');
  return (
     <>  
     <h6>
      <b>Date: {datearr}</b>
     </h6>
    <ResponsiveContainer width="100%" aspect={3}>
       
        <LineChart data={data} width={500} height={300} >
          <CartesianGrid  strokeDasharray={"2 2"} />
          <XAxis dataKey={"time"} interval={'preserveStartEnd'}/>  
          <YAxis type="number" domain={[70,110]} />
          <Tooltip  payload={data}/>
          <Legend />
          <Line dataKey={"Blood Oxygen (%)"} type="monotone" activeDot={{r:10}}  stroke='#1d75bd' strokeWidth={1} isAnimationActive={false} dot={{ stroke: '#1d75bd', strokeWidth: 1, r: 3, fill: 'white'}} />
         
        </LineChart>
    </ResponsiveContainer>
    </>
  )
}
