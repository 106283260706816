import { AuthActionTypes } from "../action-types";

const initialState = {

};

const authReducer = (state = initialState, action) => {
  switch (action.type) {

    default:
      return state;
  }
};
export default authReducer;
