import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Box from "@material-ui/core/Box";
import { Paper, Container } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
// import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

import { useHistory, NavLink } from "react-router-dom";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
// import DoctorHeader from "./DoctorHeader.js";
import { useParams, useLocation } from "react-router-dom";
import NavbarSuperAdmin from "../../components/navbar/NavbarSuperAdmin.jsx";
import CopyRight from "../../components/elements/copyright/CopyRight.js";

import { connect } from "react-redux";
import { AppActionTypes, AuthActionTypes } from "../../redux/action-types";
import {
  SET_HOSPITAL_ID,
  SET_SUPER_ADMIN_NETWORK_NESTED_ID,
} from "../../redux/action-types/app-action-types";
import { API_BASE_PATH } from "../../constants/workerConstants";

const ECG = {
  NORMAL: "Normal",
  IRREGULAR: "Irregular",
};

function randomNumberBetweenRange(min, max) {
  return Math.floor(Math.random() * (max - min) + 1) + min;
}

function transformMyData(data) {
  return data.map((item, index) => ({
    ...item,
  }));
}

const AppNetwork = (props) => {
  const classes = useStyles();
  //   const [rows, setRows] = useState([]);
  const [rows, setRows] = useState({
    users: [],
    page: 10,
  });
  const [search, setSearch] = useState("");
  const [networkAdmin, setNetworkAdmin] = useState("");
  const location = useLocation();
  const history = useHistory();

  const { id } = useParams();
  //   console.log(id);

  useEffect(() => {
    fetchData();
  }, []);
  async function fetchData() {
    const userData = localStorage.getItem("userId");
    // console.log(userData);
    const res = await fetch(`${API_BASE_PATH}/superAdmin/adminList`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        // hospital: JSON.parse(userData)
        hospital_id: id,
      }),
    }).then((res) => res.json());

    // const response = res.json();

    // console.log("data", res);
    const myData = res.result.doctors.reverse();

    // console.log(myData);
    // res.result.pop(res.result.doctors.length-1)
    setRows({ users: myData, page: rows.page });

    //   setRows(myData);
  }

  // if (wait === false && relations.length === 0) {
  //   fetchData();
  // }

  //   console.log(length);
  const viewMore = () => {
    setRows((prevState) => {
      return {
        page: prevState.page + 5,
        users: prevState.users,
      };
    });
    console.log(rows.page);
  };

  //   let length = rows.users;
  function viewLess() {
    setRows((prevState) => {
      return {
        page: 5,
        users: prevState.users,
      };
    });
  }

  function getColorClass(type, data) {
    switch (type) {
      case "heartRate":
        return data > 100 ? classes.redColor : "";
      case "ecg":
        return data === ECG.IRREGULAR ? classes.yellow : "";
      case "bloodPressure":
        return data
          ? Number(data.split("/")[0]) > 150
            ? classes.redColor
            : ""
          : "";
      default:
        return "";
    }
  }

  function handleSearch(e) {
    const { value } = e.target;
    setSearch(value);
  }

  return (
    <div>
      <NavbarSuperAdmin />

      {console.log("Aarif", location)}
      <div style={{ marginLeft: "0px" }}>
        <div>
          <TextField
            // style={{marginRight:"10px"}}
            variant="standard"
            label="Search Here"
            name="search"
            value={search}
            onChange={handleSearch}
            style={{
              float: "right",
              marginRight: "95px",
              marginTop: "-3px",
              width: "15%",
            }}
          />
        </div>
        <div>
          <React.Fragment>
            <Table
              style={{ marginTop: "80px", marginLeft: "183px", width: "77%" }}
            >
              <TableHead>
                <div class="header" style={{ marginTop: "0px" }}>
                  <KeyboardBackspaceIcon
                    onClick={() => history.push("./dashboard")}
                    style={{
                      marginTop: "70px",
                      marginLeft: "-7px",
                      height: "40px",
                      width: "40px",
                    }}
                  />
                  <h4
                    style={{
                      position: "relative",
                      marginTop: "-34px",
                      marginLeft: "40px",
                      width: "100%",
                    }}
                  >
                    <b>App Network</b>
                  </h4>
                </div>
                <TableRow>
                  <TableCell align="center">
                    <b style={{ fontSize: "14px" }}>Admin Name</b>
                  </TableCell>
                  <TableCell align="center">
                    <b style={{ fontSize: "14px" }}>First Name</b>
                  </TableCell>
                  <TableCell align="center">
                    <b style={{ fontSize: "14px" }}>Last Name</b>
                  </TableCell>
                  <TableCell align="center">
                    <b style={{ fontSize: "14px" }}>Phone Number</b>
                  </TableCell>
                  <TableCell align="center">
                    <b style={{ fontSize: "14px" }}>Email</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              {rows.users.length == 0 ? (
                <div
                  className="text-center mt-5 "
                  style={{
                    backgroundColor: "rgb(29, 117, 189)",
                    color: "rgb(255, 255,255)",
                    padding: "0.5rem",
                    marginRight: "-45rem",
                  }}
                >
                  No Data Available
                </div>
              ) : (
                <TableBody>
                  {/* {console.log("aki", rows)} */}
                  {rows.users
                    .filter((i) =>
                      search.length > 0
                        ? i.firstName
                            .toLowerCase()
                            .startsWith(search.toLowerCase())
                        : i
                    )
                    .slice(0, rows.page)
                    .map((row) => (
                      <TableRow key={row.id}>
                        {/* <NavLink to={`/superadminhospitaldetail/${row._id}`}><TableCell style={{fontSize:"12px"}}><b>{row.hospitalName}</b></TableCell></NavLink>  */}
                        <TableCell
                          align="center"
                          style={{ fontSize: "12px" }}
                          onClick={() => {
                            props.dispatchPatientNetworkId(row._id);
                            props.dispatchNestedNetworkId(row.network._id);
                            history.push({
                              pathname: "/patientlist",
                              state: row,
                            });
                          }}
                        >
                          <b
                            style={{
                              color: "black",
                              cursor: "pointer",
                              textDecoration: "underline",
                            }}
                          >
                            {row.username}
                          </b>
                        </TableCell>
                        <TableCell align="center" style={{ fontSize: "12px" }}>
                          <b>{row.firstName}</b>
                        </TableCell>
                        <TableCell align="center" style={{ fontSize: "12px" }}>
                          <b>{row.lastName}</b>
                        </TableCell>
                        <TableCell align="center" style={{ fontSize: "12px" }}>
                          <b>{row.phno}</b>
                        </TableCell>
                        <TableCell align="center" style={{ fontSize: "12px" }}>
                          <b>{row.emailId}</b>
                        </TableCell>
                      </TableRow>
                    ))}
                  {
                    <div
                      className="text-center mt-5"
                      style={{ marginRight: "-45rem" }}
                    >
                      {rows.page >= rows.users.length &&
                      rows.users.length > 5 ? (
                        <div>
                          <button
                            className="btn btn-warning "
                            onClick={viewLess}
                          >
                            View Less
                          </button>
                        </div>
                      ) : (
                        <div>
                          {rows.users.length > 5 ? (
                            <button
                              className="btn"
                              style={{
                                backgroundColor: "rgb(29, 117, 189)",
                                color: "rgb(255, 255,255)",
                              }}
                              onClick={viewMore}
                            >
                              View More
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                    </div>
                  }
                </TableBody>
              )}
            </Table>

            {/* <div className={classes.seeMore}>
        <Link color="primary" to="/patientdetail" >
          view More
        </Link>
      </div> */}
          </React.Fragment>
        </div>
        )
        <Box pt={4}>
          <CopyRight />
        </Box>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchPatientNetworkId: (id) =>
      dispatch({
        type: AppActionTypes.SET_APP_NETWORK_ID,
        payload: id,
      }),
    dispatchNestedNetworkId: (id) =>
      dispatch({
        type: AppActionTypes.SET_SUPER_ADMIN_NETWORK_NESTED_ID,
        payload: id,
      }),
  };
};

export default connect(null, mapDispatchToProps)(AppNetwork);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  theader: {
    "& th": {
      fontSize: "1.4rem",
      fontWeight: "bold",
    },
  },
  row: {
    "& td": {
      fontSize: "1.2rem",
      fontWeight: 600,
      padding: "6px",
    },
    "& a": {
      color: theme.palette.common.black,
    },
  },
  redColor: {
    background: "red",
    color: theme.palette.common.white,
    "& a": {
      color: theme.palette.common.white,
    },
  },
  yellow: {
    background: "yellow",
    color: theme.palette.common.black,
    "& a": {
      color: theme.palette.common.black,
    },
  },
}));
