import React, { useEffect, useState } from "react";
import NavbarSuperAdmin from "../../../components/navbar/NavbarSuperAdmin";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Axios from "axios";
import { API_BASE_PATH } from "../../../constants/workerConstants";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import ConfirmDialog from "../../../components/ui/app-modal/ConfirmDialog";
function transformMyData(data) {
  return data.map((item, index) => ({
    ...item,
  }));
}
const DeletePatinent = (props) => {
  const [rows, setRows] = useState({
    users: [],
    page: 10,
  });
  const [search, setSearch] = useState("");
  const [patientsInfo, setPatientInfo] = useState({ isOpen: false });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    text: "",
  });

  useEffect(() => {
    if (props.userData) {
      getdeletepatinent();
    }
  }, [props.userData]);

  const getdeletepatinent = () => {
    Axios.post(`${API_BASE_PATH}/user/removedPatientList`, {
      networkAdmin: props.userData._id,
    }).then((res) => {
      console.log("res", res);
      let myData = transformMyData(res.data.result);
      if (myData.length) {
        myData = myData.filter((data) => {
          // console.log("akshay__check", data._id !== props.userData._id);
          return data._id !== props.userData._id;
        });
        setRows({ users: myData, page: rows.page });
        // setRows(myData);
        // console.log(myData);
        // console.log('akshay__check', props.userData._id);
      }
    });
  };
  function handleSearch(e) {
    const { value } = e.target;
    setSearch(value);
  }
  const viewMore = () => {
    setRows((prevState) => {
      return {
        page: prevState.page + 5,
        users: prevState.users,
      };
    });
    //   console.log(rows);
  };

  const viewLess = () => {
    setRows((prevState) => {
      return {
        page: 5,
        users: prevState.users,
      };
    });
  };
  const onAdd = (id) => {
    console.log("your id is" + id);
    Axios.post(
      `${API_BASE_PATH}/user/addRemovedSenior`,
      { id: id },
      {
        headers: {
          "Content-Type": "application/json",
          authorization: props.authToken,
          // Authorization:
          //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MTc4NzI4M2U1MGQ1YTY5MWM2ODFiNDciLCJpYXQiOjE2NDY2MzcyNjR9.YN5kUm9Xlidnk3_DOIGLNCJ-Ho7asQmosH8p7r1wTH0",
        },
      }
    ).then((res) => {
      if (res.status === 200) {
        setConfirmDialog({ isOpen: false });
        getdeletepatinent();
      }
    });
  };
  return (
    <div>
      <NavbarSuperAdmin />
      <div style={{ marginLeft: "0px" }}>
        <React.Fragment>
          <Table
            style={{
              marginLeft: "166px",
              width: "80%",
              marginBottom: "72px",
              marginTop: "100px",
            }}
          >
            <TableHead>
              <h4 style={{ marginTop: "10px", marginBottom: "25px" }}>
                Deleted Patient's List
              </h4>

              <TableRow>
                <TableCell align="left">
                  <b style={{ fontSize: "14px" }}>User Name</b>
                </TableCell>
                <TableCell align="center">
                  <b style={{ fontSize: "14px" }}>First Name</b>
                </TableCell>
                <TableCell align="center">
                  <b style={{ fontSize: "14px" }}>Last Name</b>
                </TableCell>
                <TableCell align="center">
                  <b style={{ fontSize: "14px" }}>EmailId</b>
                </TableCell>
                <TableCell align="center">
                  <b style={{ fontSize: "14px" }}>Phone No.</b>
                </TableCell>

                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            {rows.users.length == 0 ? (
              <div
                className="text-center mt-5 "
                style={{
                  backgroundColor: "rgb(29, 117, 189)",
                  color: "rgb(255, 255,255)",
                  padding: "0.5rem",
                  marginRight: "-45rem",
                  width: "207%",
                }}
              >
                No Data Available
              </div>
            ) : (
              <TableBody>
                {rows.users
                  .filter((i) =>
                    search.length > 0
                      ? i.firstName
                          .toLowerCase()
                          .startsWith(search.toLowerCase())
                      : i
                  )
                  .slice(0, rows.page)
                  .map((row) => (
                    <TableRow key={row.id}>
                      <TableCell align="left" style={{ fontSize: "12px" }}>
                        <b style={{ color: "black" }}>
                          {row.username == null ? (
                            <div>{"-"}</div>
                          ) : (
                            <div
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              {row.username}
                            </div>
                          )}
                        </b>
                      </TableCell>
                      <TableCell align="center" style={{ fontSize: "12px" }}>
                        <b>{row.firstName == null ? "-" : row.firstName}</b>
                      </TableCell>
                      <TableCell align="center" style={{ fontSize: "12px" }}>
                        <b>{row.lastName == null ? "-" : row.lastName}</b>
                      </TableCell>
                      <TableCell align="center" style={{ fontSize: "12px" }}>
                        <b>{row.lastName == null ? "-" : row.emailId}</b>
                      </TableCell>
                      <TableCell align="center" style={{ fontSize: "12px" }}>
                        <b>{row.lastName == null ? "-" : row.phno}</b>
                      </TableCell>

                      <TableCell align="center" style={{ fontSize: "12px" }}>
                        <Button
                          variant="contained"
                          onClick={() => {
                            setConfirmDialog({
                              isOpen: true,
                              text: "Are you sure you want to readmit the patient",
                              onConfirm: () => {
                                onAdd(row._id);
                              },
                            });
                          }}
                          style={{
                            cursor: "pointer",
                            color: "white",
                            background: "green",
                          }}
                        >
                          {" "}
                          Readmit
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                {
                  // <div
                  //   className="text-center mt-5"
                  //   style={{ marginRight: "-45rem" }}
                  // >
                  //   {rows.page >= rows.users.length && rows.users.length > 5 ? (
                  //     <div>
                  //       <button className="btn btn-warning " onClick={viewLess}>
                  //         View Less
                  //       </button>
                  //     </div>
                  //   ) : (
                  //     <div>
                  //       {rows.users.length > 5 ? (
                  //         <button
                  //           className="btn"
                  //           style={{
                  //             backgroundColor: "rgb(29, 117, 189)",
                  //             color: "rgb(255, 255,255)",
                  //           }}
                  //           onClick={viewMore}
                  //         >
                  //           View More
                  //         </button>
                  //       ) : (
                  //         ""
                  //       )}
                  //     </div>
                  //   )}
                  // </div>
                }
              </TableBody>
            )}
          </Table>
        </React.Fragment>
      </div>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </div>
  );
};
const mapStateToProps = (state) => {
  console.log(state);
  return {
    authToken: state.persist.authToken,
    userData: state.persist.userData,
  };
};

// export default DeletePatinent;
export default connect(mapStateToProps)(DeletePatinent);
