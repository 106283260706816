import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  Button,
  TextField,
  Paper,
  Container,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import axios from "axios";
import { connect } from "react-redux";
import { API_BASE_PATH } from "../../../constants/workerConstants";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { DateTimePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  FormControl: {
    minWidth: 350,
  },
}));

function AddAppointments(props) {
  const { addDialog, setAddDialog } = props;
  const classes = useStyles();
  const [userName, setUserName] = useState();
  const [firstId, setFirstId] = useState();
  const [secondId, setSecondId] = useState();
  const history = useHistory();
  const [value, setValue] = useState("");
  const [location, setLocation] = useState("");
  const [selectedDate, setSelectedDate] = useState("00:00");
  const [reminder, setReminder] = useState("");

  useEffect(() => {
    getName();
  }, []);

  const handleChange = (e) => {
    setValue(e.target.value);
    console.log("setValue", value);
  };
  const handleChangeDate = (date) => {
    setSelectedDate(date);
    console.log("setSelectedDate", selectedDate);
  };
  const handleChangeReminder = (e) => {
    setReminder(e.target.value);
    console.log("setReminder", e.target.value);
  };
  const handleChangeLocation = (e) => {
    setLocation(e.target.value);
    console.log("setLocation", e.target.value);
  };
  const getName = async () => {
    axios
      .get(
        `${API_BASE_PATH}/user/getMemberList`,

        {
          headers: {
            authorization: props.authToken,
          },
        }
      )
      .then((resp) => {
        if (resp.status === 200) {
          setUserName(resp.data.result.list[0].firstName);
          setFirstId(resp.data.result.list[0]._id);
          console.log("resp", resp.data.result.list[0]._id);
          setSecondId(resp.data.result.list[1]._id);
        }
      });
  };

  const passData = async () => {
    if (value.trim() == "") {
      toast.error(" Please enter appointment content.");
      return;
    } else
      axios
        .post(
          `${API_BASE_PATH}/todo/addAppointment `,
          {
            date: "" + new Date(new Date(addDialog.date).toDateString()),
            content: value,
            reminder: reminder,
            location: location,
            // time: selectedDate,
            time: new Date(selectedDate).toLocaleTimeString(),

            members: [firstId, secondId],
          },
          {
            headers: {
              authorization: props.authToken,
            },
          }
        )
        .then((resp) => {
          if (resp.status === 200) {
            var a = new Date(new Date(addDialog.date).toDateString());
            addDialog.xyzFun(a);

            setAddDialog({ isOpen: false });
            setValue("");
            // window.location.reload();
          }
          console.log("uses", resp);
        });
  };

  return (
    <div>
      <Dialog
        open={addDialog.isOpen}
        PaperProps={{
          style: {
            minWidth: "250px",
            overflow: "hidden",
            width: "26%",
          },
        }}
      >
        <DialogTitle style={{ backgroundColor: "rgb(29, 117, 189)" }}>
          {" "}
          <div
            style={{
              backgroundColor: "rgb(29, 117, 189)",
              display: "flex",
              color: "white",
            }}
          >
            <Typography
              variant="h6"
              style={{ flexGrow: 1, textAlign: "center", width: "20%" }}
            >
              {/* Add Notes */}
              {addDialog.text}
            </Typography>
          </div>
        </DialogTitle>
        <Container width="50%" height="50%">
          <TextField
            required
            value={value}
            multiline={true}
            rows={4}
            fullWidth
            label={addDialog.text}
            variant="filled"
            margin="normal"
            inputProps={{
              style: {
                height: "40%",
              },
            }}
            onChange={handleChange}
          />
        </Container>

        <DialogContent
          style={{
            fontWeight: "bold",
            alignItems: "left",
            color: "rgb(29, 117, 189)",
          }}
        >
          <Typography>{addDialog.date}</Typography>
        </DialogContent>
        <Container>
          {" "}
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <TimePicker
              autoOk
              fullWidth
              //   ampm={false}
              value={selectedDate}
              onChange={handleChangeDate}
              label="Select Time"
            />
          </MuiPickersUtilsProvider>{" "}
        </Container>
        <Container>
          <FormControl className={classes.FormControl}>
            <InputLabel>Select reminder</InputLabel>
            <Select onChange={handleChangeReminder}>
              <MenuItem value={5 + " mintues"}> </MenuItem>
              <MenuItem value={5 + " mintues"}> 5 Minutes</MenuItem>
              <MenuItem value={10 + " mintues"}> 10 Minutes</MenuItem>
              <MenuItem value={30 + " Minutes"}> 30 Minutes</MenuItem>
              <MenuItem value={45 + " Minutes"}> 45 Minutes</MenuItem>
              <MenuItem value={60 + " Minutes"}> 60 Minutes</MenuItem>
            </Select>
          </FormControl>{" "}
        </Container>
        <Container width="50%" height="50%">
          <TextField
            value={location}
            multiline={true}
            rows={1}
            fullWidth
            label="Add Location"
            variant="filled"
            margin="normal"
            inputProps={{
              style: {
                height: "40%",
              },
            }}
            onChange={handleChangeLocation}
          />
        </Container>

        <DialogContent
          style={{ color: "rgb(29, 117, 189)", textAlign: "center" }}
        >
          {userName}
        </DialogContent>
        <DialogContent style={{ marginLeft: "5px", marginLeft: "auto" }}>
          <DialogTitle>
            {" "}
            <div
              style={{
                display: "flex",
              }}
            >
              <Button
                onClick={passData}
                variant="contained"
                style={{
                  display: "flex",
                  color: "white",
                  backgroundColor: "rgb(29, 117, 189)",
                }}
              >
                Done
              </Button>
              <Button
                onClick={() => setAddDialog({ isOpen: false })}
                variant="contained"
                style={{
                  marginLeft: "6px",
                  display: "flex",
                  backgroundColor: "white",
                  color: "rgb(29, 117, 189)",
                }}
              >
                Close
              </Button>
            </div>
          </DialogTitle>
        </DialogContent>
      </Dialog>
    </div>
  );
}
const mapStateToProps = (state) => {
  console.log(state);
  return {
    authToken: state.persist.authToken,
    userData: state.persist.userData,
  };
};
export default connect(mapStateToProps)(AddAppointments);
