import { combineReducers } from "redux";
import authReducer from "./auth.reducer";
import appReducer from "./app";
import persistedReducer from "./persistedReducer";
import networkIDReducer from "./networkID";
import memberListReducer from "./memberListReducer";

export const RootReducer = combineReducers({
  appReducer,
  authReducer,
  persist: persistedReducer,
  networkReducer: networkIDReducer,
  memberListReducer,
});
