import React from "react";
import loading from "./loading (1).gif";

function Spinner() {
  return (
    <div>
      <div
        style={{
          width: "20%",
          margin: "auto",
          display: "block",
          position: "relative",
          top: "194px",
          left: "120px",
        }}
      >
        <img src={loading} alt="loading" />
      </div>
    </div>
  );
}

export default Spinner;
