import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
import NavbarSuperAdmin from "../../../components/navbar/NavbarSuperAdmin";
import { Paper, Typography } from "@material-ui/core";
import { AiFillMessage } from "react-icons/ai";
import { FaWifi, FaCalendarAlt } from "react-icons/fa";
import { IoPersonAddSharp } from "react-icons/io5";
import { IoPersonSharp } from "react-icons/io5";
import { FaHistory, FaList } from "react-icons/fa";
import PatientInfoDialog from "../../../components/ui/app-modal/patientInfoDialog";
import { GoToBack } from "../../../components/ui/GoToBack header/GoToBack";

const PatientDetails = (props) => {
  const { id } = useParams();
  const { memberList } = props;
  const history = useHistory();
  const [patientsInfo, setPatientInfo] = useState({ isOpen: false });

  let patientData;

  if (memberList && memberList?.network?.members?.length)
    patientData = memberList.network.members.find((el) => el._id === id);

  console.log("patientData", patientData);

  const btnList = [
    {
      icon: <IoPersonSharp />,
      text: "Profile",
      handleClick: () => history.push(`${id}/patientprofile`),
    },
    {
      icon: <FaCalendarAlt />,
      text: "Calender",
      handleClick: handleClickCalender,
    },
    {
      icon: <FaHistory />,
      text: "Call Log",
      handleClick: () => history.push(`${id}/call-log`),
    },
    {
      icon: <AiFillMessage />,
      text: "Social Messenger",
      handleClick: () => history.push(`${id}/chat`),
    },
    {
      icon: <FaWifi />,
      text: "Social Network",
      handleClick: () => history.push(`${id}/media-gallery`),
    },
    {
      icon: <IoPersonAddSharp />,
      text: "Add Care Giver",
      handleClick: () => history.push(`${id}/addcaregiver`),
    },
    {
      icon: <FaList />,
      text: "Care Givers List",
      handleClick: () => history.push(`${id}/caregiverslist`),
    },
  ];

  function handleClickCalender() {
    setPatientInfo({
      isOpen: true,
    });
  }
  return (
    <div>
      <NavbarSuperAdmin />

      <div className="pl">
        <GoToBack>{patientData?.firstName}'s Details</GoToBack>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: "25px",
          }}
        >
          {btnList.map((item) => (
            <Paper
              variant="h4"
              elevation={3}
              style={{
                height: "35px",
                textAlign: "center",
                maxWidth: "400px",
                width: "100%",
              }}
            >
              <Typography
                variant="h6"
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "5px",
                  padding: "0 10px",
                }}
                onClick={item.handleClick}
              >
                {item.icon} {item.text}
              </Typography>
            </Paper>
          ))}
        </div>
      </div>

      <PatientInfoDialog
        patientsInfo={patientsInfo}
        setPatientInfo={setPatientInfo}
        id={id}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    memberList: state.memberListReducer.memberList,
  };
};

export default connect(mapStateToProps)(PatientDetails);
