import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "./login.css";
import {
  Button,
  FormGroup,
  FormControl,
  ControlLabel,
  InputGroup,
} from "react-bootstrap";
import eye from "../../../constants/eye.png";

import { connect } from "react-redux";
import { AuthActionTypes } from "../../../redux/action-types";
import Axios from "axios";
import { API_BASE_PATH } from "../../../constants/workerConstants";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

toast.configure();

const Login = (props) => {
  console.log("data", props);
  const history = useHistory();
  const [state, setState] = useState({
    email: "",
    password: "",
    revealPassword: "",
    disableSubmit: true,
  });
  const onFormSubmit = () => {
    if (state.email !== "" && state.password !== "") {
      login();
    } else {
      //   alert("Check your Credentials");
      toast.error("Username or Password can't be empty");
    }
  };
  const isFormValid = () => state.email.length > 0 && state.password.length > 0;

  const updateInputField = (event) => {
    const { name, value } = event.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  // redirect if user data is present.
  React.useEffect(() => {
    // console.log("debug akshay", props.userData);

    if (props.userData) {
      history.push("/dashboard");
    } else {
      history.push("/");
    }
  }, [props.userData]);

  const login = () => {
    Axios.post(
      `${API_BASE_PATH}/user/login`,
      {
        username: state.email,
        password: state.password,
      },
      {
        headers: {
          "content-type": "application/json",
        },
      }
    )
      .then((res) => {
        console.log("respnse", res);

        // const allowCondition =
        //   state.email === "keshav@senior.com" &&
        //   state.password === "Ujjainia7879";

        if (true || res.data.result.user.userRole !== 1) {
          props.loginUser(res.data.result.user, res.data.result.authToken);
          console.log(res.data.result.user);
          toast("Login Sucessfully");
          // getMemberList(res.data.result.authToken);
        } else {
          toast.error("You do not have access");
        }
      })
      .catch((err) => {
        let erro = err.response.data.responseMessage;
        toast.error(erro);
      });
  };

  return (
    <div className="Login">
      <form>
        <FormGroup
          controlId="email"
          bsSize="large"
          style={{ marginBottom: "15px" }}
        >
          <ControlLabel
            style={{
              display: "inlineBlock",
              marginBottom: "5px",
              fontWeight: "700",
              maxWidth: "100%",
            }}
          >
            Username
          </ControlLabel>
          <FormControl
            autoFocus
            type="text"
            name="email"
            value={state.email}
            onChange={updateInputField}
            required={true}
            style={{ height: "46px", fontWeight: "499", fontSize: "18px" }}
          />
        </FormGroup>
        <FormGroup
          controlId="password"
          bsSize="large"
          style={{ marginBottom: "15px" }}
        >
          <ControlLabel
            style={{
              display: "inlineBlock",
              marginBottom: "5px",
              fontWeight: "700",
              maxWidth: "100%",
            }}
          >
            Password
          </ControlLabel>
          <InputGroup>
            <FormControl
              type={state.revealPassword ? "text" : "password"}
              value={state.password}
              name="password"
              onChange={updateInputField}
              required={true}
              style={{ height: "46px" }}
            />
            <InputGroup.Addon
              style={{
                padding: "4px",
                borderLeft: "0",
                verticalAlign: "middle",
                border: "1px solid #ccc",
                textAlign: "center",
              }}
              onClick={() =>
                updateInputField({
                  target: {
                    name: "revealPassword",
                    value: !state.revealPassword,
                  },
                })
              }
            >
              <img src={eye} alt="eye" style={{ height: "36px" }} />
            </InputGroup.Addon>
          </InputGroup>
        </FormGroup>
        <Button
          block
          bsSize="large"
          style={{
            backgroundColor: "rgb(29, 117, 189)",
            color: "rgb(255, 255,255)",
            marginBottom: "11px",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => {
            onFormSubmit();
          }}
        >
          Login
        </Button>
        <br />
        <a
          className="btn btn-lg btn-default btn-block"
          style={{
            backgroundColor: "rgb(29, 117, 189)",
            color: "rgb(255, 255,255)",
            marginBottom: "15px",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => history.push("forgot_password")}
        >
          Forgot Password
        </a>
        <Button
          block
          bsSize="large"
          style={{
            backgroundColor: "rgb(29, 117, 189)",
            color: "rgb(255, 255,255)",
            marginBottom: "11px",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => history.push("addadminform")}
        >
          Create Network
        </Button>
      </form>
      {/* <ToastContainer /> */}
    </div>

    // </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.persist.userData,
    memberList: state.memberListReducer.memberList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loginUser: (userData, token) =>
      dispatch({
        type: AuthActionTypes.LOGIN,
        payload: userData,
        token,
      }),
    getMemberList: (data) =>
      dispatch({
        type: "memberList/data",
        payload: data,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
