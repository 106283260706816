import React from "react";
import { connect } from "react-redux";
import NavbarSuperAdmin from "../../../components/navbar/NavbarSuperAdmin";
import { GoToBack } from "../../../components/ui/GoToBack header/GoToBack";
import AddPatient from "../../addPatient/AddPatient";

const DashboardPatinentProfile = (props) => {
  let patientData;

  if (props.userData && props.userData?.network?.members?.length) {
    patientData = props.userData.network.members.find(
      (el) => el._id === props.userData._id
    );
    patientData = {
      ...patientData,
      networkName: props.userData?.network?.networkName,
    };

    if (props.userData.hospital) {
      patientData = {
        ...patientData,
        hospital: props.userData.hospital.hospitalName,
      };
    }
    if (props.userData.relationship) {
      patientData = {
        ...patientData,
        relationship: props.userData.relationship.name,
      };
    }
  }

  return (
    <div>
      <NavbarSuperAdmin />

      <div className="pl">
        <GoToBack>Your Profile</GoToBack>
        {patientData && (
          <AddPatient
            // editProfile={true}
            isReadOnly={true}
            patientData={patientData}
            isTitle={false}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.persist.userData,
    memberList: state.memberListReducer.memberList,
  };
};

export default connect(mapStateToProps)(DashboardPatinentProfile);
